// check if first params is ask for access, if true => review if at least one of "rolesAccess" are include in list of allowed roles,
// 3rd param if it's from Asuite or The Hub
export const verifyLinkAccess = (
  requireAccess: boolean | undefined,
  rolesAccess: string[] | undefined,
  thehubAccess: boolean,
  userRolesList: string[],
  asuitePermissionList: string[]
) => {
  let response =
    !requireAccess ||
    (requireAccess &&
      rolesAccess?.some((elem) =>
        thehubAccess
          ? userRolesList.includes(elem)
          : asuitePermissionList.includes(elem)
      ));
  return response;
};

import { CheckSkeleton } from "../../SharedModule/utils/checkSkeleton";
import { quantityFormat } from "../../SharedModule/utils/formatters";

export const SummaryTotals = (props) => {
  let slash = "—";

  let missingHours =
    !props.avoidCheckMissingHours &&
    props.totals !== 0 &&
    props.totals - (new Date().getDay() - 1) * 8 < 0;

  return (
    <>
      {!props.isLoading && props.showTitle && (
        <div className="summary-title">
          Summary totals:{" "}
          <span className="summary-title-value ms-1 toCapitalize">
            {props.showedWeek}
          </span>
        </div>
      )}
      <div
        className="card d-flex flex-row flex-wrap invoice-summary-background"
        style={{ marginTop: "12px" }}
      >
        <div className="px-4 py-3 flex-fill border-end">
          <div className={`${missingHours ? "text-error" : ""}`}>
            Total hours
          </div>
          <CheckSkeleton isLoading={props.isLoading} classes="fs-4 pb-1">
            <div
              className={`fs-4 fw-bold pb-1 ${
                missingHours ? "text-error" : ""
              }`}
            >
              {props.totals !== 0 ? quantityFormat(props.totals) : slash}
            </div>
          </CheckSkeleton>
        </div>
        <div className="px-4 py-3 flex-fill border-end">
          <div>Billable hours</div>
          <CheckSkeleton isLoading={props.isLoading} classes="fs-4 pb-1">
            <div className="fs-4 fw-bold pb-1">
              {props.billable !== 0 ? quantityFormat(props.billable) : slash}
            </div>
          </CheckSkeleton>
        </div>
        <div className="px-4 py-3 flex-fill border-end">
          <div>Financed hours</div>
          <CheckSkeleton isLoading={props.isLoading} classes="fs-4 pb-1">
            <div className="fs-4 fw-bold pb-1">
              {props.financed !== 0 ? quantityFormat(props.financed) : slash}
            </div>
          </CheckSkeleton>
        </div>
        <div className="px-4 py-3 flex-fill border-end">
          <div>Non-Billable hours</div>
          <CheckSkeleton isLoading={props.isLoading} classes="fs-4 pb-1">
            <div className="fs-4 fw-bold pb-1">
              {props.nonBillable !== 0
                ? quantityFormat(props.nonBillable)
                : slash}
            </div>
          </CheckSkeleton>
        </div>
        <div className="px-4 py-3 flex-fill border-end">
          <div>Internal hours</div>
          <CheckSkeleton isLoading={props.isLoading} classes="fs-4 pb-1">
            <div className="fs-4 fw-bold pb-1">
              {props.internal !== 0 ? quantityFormat(props.internal) : slash}
            </div>
          </CheckSkeleton>
        </div>
        <div className="px-4 py-3 flex-fill">
          <div>Time Off hours</div>
          <CheckSkeleton isLoading={props.isLoading} classes="fs-4 pb-1">
            <div className="fs-4 fw-bold pb-1">
              {props.timeOff !== 0 ? quantityFormat(props.timeOff) : slash}
            </div>
          </CheckSkeleton>
        </div>
      </div>
    </>
  );
};

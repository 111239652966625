import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { BiInfoCircle, BiLockAlt, BiCheckCircle } from "react-icons/bi";
import { Select } from "../../SharedModule/components/Select";
import { Calendar } from "../../SharedModule/components/Calendar";
import { useApprovalTime } from "../hooks/useApprovalTime";
import { checkDataIsLoaded, hoursTypeOptions } from "../utils/timesUtils";
import { useEffect, useState } from "react";
import {
  LOCKED,
  ROUTE_APPROVE_TIME_TIMESHEETS,
  ROUTE_APPROVE_TIME_TIMESHEETS_BY_USER,
} from "../../SharedModule/utils/constants";
import {
  dateFormatEntryEdit,
  quantityFormat,
} from "../../SharedModule/utils/formatters";
import { UserToApprove } from "../types";
import {
  changeOrder,
  loadTimeEntriesInfo,
  resetFilters,
  SelectFilters,
  selectOrderCriteria,
  setFilters,
  setLoading,
} from "../redux/reducers/approvalTime.reducer";
import { useDispatch, useSelector } from "react-redux";
import { TimesheetsService } from "../services/timesheets";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { Spinner } from "../../SharedModule/components/Spinner";
import { setActiveTab } from "../../ProductsModule/redux/reducers/ui.reducer";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import eventHandler from "../../SharedModule/utils/eventHandler";
import { useNavigate } from "react-router-dom";
import { setSearchState } from "../redux/reducers/approveTimeByUser.reducer";

export const ApproveTimePage = () => {
  const filters = useSelector(SelectFilters);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sortBy = useSelector(selectOrderCriteria);

  const { isLoading, MetaTimesInfo, userList, isTimeAdmin } =
    useApprovalTime(filters);

  let userListToShow: UserToApprove[] | any = userList;

  const { trackPageView } = useMatomo();

  useEffect(() => {
    dispatch(setActiveTab(ROUTE_APPROVE_TIME_TIMESHEETS));
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    // should go in every times page
    if (!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)) {
      eventHandler.dispatch("loadTimesStaticInfo", {
        isTimeAdmin: isTimeAdmin,
      });
    }

    dispatch(
      changeOrder({
        orderBy: "userName",
        dataType: "string",
        criteria: "asc",
      })
    );

    return () => {
      // reset filters if user got to another page
      if (window.location.pathname !== ROUTE_APPROVE_TIME_TIMESHEETS_BY_USER) {
        //  clean filters
        dispatch(resetFilters());
      }
    };
  }, [trackPageView, isTimeAdmin]);

  const [entriesSelected, setEntriesSelected] = useState<UserToApprove[] | any>(
    []
  );
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(true);

  const isSelected = (entry: any) => {
    let response = entriesSelected.indexOf(entry);
    return response > -1;
  };

  const updatedSelected = (entry: UserToApprove) => {
    let response = entriesSelected.indexOf(entry);
    if (response < 0) {
      const dataUpdated = [entry, ...entriesSelected];
      setEntriesSelected(dataUpdated);
    } else {
      setEntriesSelected(
        entriesSelected.filter((item) => item.userId !== entry.userId)
      );
    }
  };

  const [filterOptions, setFilterOptions] = useState({
    projectOwners:
      MetaTimesInfo.managersApprovalTime?.length === 1
        ? [MetaTimesInfo.managersApprovalTime[0]]
        : filters.projectOwners,
    costCenters: filters.costCenters,
    projects: filters.projects,
    billableTypes: filters.billableTypes,
    endDate: filters.endDate,
  });

  const submit = () => {
    dispatch(setFilters(filterOptions));
  };

  const checkAll = (e: any) => {
    setAllSelected(e.target.checked);
    if (e.target.checked) {
      setEntriesSelected(userList);
    } else {
      setEntriesSelected([]);
    }
  };

  const changeToAprroved = (user: UserToApprove) => {
    return {
      ...user,
      status: "Approved",
      billableHours: 0,
      nonBillableHours: 0,
      internalHours: 0,
      workOrderHours: 0,
      pendingHours: 0,
      billableIds: [],
      nonBillableIds: [],
      internalIds: [],
      workOrderIds: [],
    };
  };

  const approveTimeEntries = async () => {
    dispatch(setLoading(true));

    let totalEntries: number[] = [];
    entriesSelected.forEach((elem) => {
      let partial = elem.billableIds
        .concat(elem.nonBillableIds)
        .concat(elem.internalIds)
        .concat(elem.workOrderIds);

      totalEntries = totalEntries.concat(partial);
    });

    let body = {
      timeEntriesIds: totalEntries,
      action: {
        id: 3,
        nonBillableReasonId: null,
        entryDate: null,
        entityId: null,
        categoryId: null,
      },
    };

    await TimesheetsService.updateTimeEntries(body)
      .then((response) => {
        dispatch(addToast({ mode: "success", message: response.data.message }));

        // transform obejct to render
        userListToShow = userListToShow?.map((user: UserToApprove) => {
          if (entriesSelected.includes(user)) {
            return changeToAprroved(user);
          } else {
            return user;
          }
        });
        dispatch(loadTimeEntriesInfo(userListToShow));

        // clean entries selected
        setEntriesSelected([]);
        setAllSelected(false);
        setSubmitEnabled(!checkNothingToApprove());
      })
      .catch((error) => {
        dispatch(addToast({ mode: "error", message: error }));
      });
  };

  const checkNothingToApprove = () => {
    return (
      userListToShow &&
      !userListToShow.some((elem) => elem.status === "Pending")
    );
  };

  const handleChange = (value: any, field: string) => {
    setSubmitEnabled(true);
    setFilterOptions({
      ...filterOptions,
      [field]: value,
    });
  };

  const handleClick = (user: UserToApprove, hoursType: string) => {
    dispatch(
      setSearchState({ user: user, filters: filters, hoursType: hoursType })
    );
    navigate(ROUTE_APPROVE_TIME_TIMESHEETS_BY_USER);
  };

  return (
    <div className="content">
      <div className="content-header w-100" style={{ margin: "0 0 20px" }}>
        <h2>Approve time</h2>
      </div>
      <div className="d-flex mb-4 justify-content-between">
        <div className="flex-column col-sm-2">
          <label className="col-form-label">
            Project Owner
            <Tooltip
              title={
                <b>
                  Time in projects that are assigned to this Project Manager
                </b>
              }
              placement="right"
              TransitionComponent={Zoom}
              arrow
            >
              <span>
                <BiInfoCircle className="disc-icon ms-1" />
              </span>
            </Tooltip>
          </label>
          <Select
            isMulti
            placeholder="All"
            options={MetaTimesInfo.managersApprovalTime}
            value={filterOptions.projectOwners}
            onChange={(projectOwner) =>
              handleChange(projectOwner, "projectOwners")
            }
            isDisabled={
              isLoading || !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)
            }
          />
        </div>
        <div className="flex-column col-sm-2">
          <label className="col-form-label">
            Cost Center
            <Tooltip
              title={
                <b>Time for users that are assigned to this Cost Center</b>
              }
              placement="right"
              TransitionComponent={Zoom}
              arrow
            >
              <span>
                <BiInfoCircle className="disc-icon ms-1" />
              </span>
            </Tooltip>
          </label>
          <Select
            isMulti
            placeholder="All Cost Centers"
            options={MetaTimesInfo.costCentersApprovalTime}
            value={filterOptions.costCenters}
            onChange={(costCenter) => handleChange(costCenter, "costCenters")}
            isDisabled={
              isLoading || !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)
            }
          />
        </div>
        <div className="flex-column col-sm-2">
          <label className="col-form-label">Project</label>
          <Select
            isMulti
            placeholder="All"
            options={MetaTimesInfo.projectsApprovalTime}
            value={filterOptions.projects}
            onChange={(project) => handleChange(project, "projects")}
            isDisabled={
              isLoading || !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)
            }
          />
        </div>
        <div className="flex-column col-sm-2">
          <label className="col-form-label">Billable/Non-billable</label>
          <Select
            isMulti
            placeholder="All"
            options={hoursTypeOptions}
            value={filterOptions.billableTypes}
            onChange={(billableType) =>
              handleChange(billableType, "billableTypes")
            }
            isDisabled={
              isLoading || !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)
            }
          />
        </div>
        <div className="flex-column col-sm-2">
          <label className="col-form-label">End date</label>
          <Calendar
            className="pointer"
            date={filterOptions.endDate}
            onChange={(date: Date) => handleChange(date, "endDate")}
            isDisabled={
              isLoading || !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)
            }
          />
        </div>
        <div className="d-flex flex-column" style={{ marginTop: "35px" }}>
          <button
            className="btn btn-primary"
            onClick={() => submit()}
            disabled={!submitEnabled}
          >
            Submit
          </button>
        </div>
      </div>

      <hr className="header-separator" />

      {isLoading && (
        <Spinner
          style={{
            marginLeft: "45%",
            marginTop: "10%",
          }}
        />
      )}

      {!isLoading && (
        <div className="d-flex mt-3 justify-content-end">
          <button
            className="btn button-secondary"
            onClick={() => approveTimeEntries()}
            disabled={entriesSelected.length === 0 || checkNothingToApprove()}
          >
            Approve
          </button>
        </div>
      )}

      {!isLoading && checkNothingToApprove() && (
        <div className="card-success mt-3">
          <div className="success-message mt-3">
            <BiCheckCircle className="success-icon big ms-3" />
            <span className="green-text ms-2">Nice Work!</span>
            <span className="black-text ms-2">
              There is no more time to approve here.
            </span>
          </div>
        </div>
      )}

      {!isLoading && (
        <table
          className="grey-table-header-background table mt-3 mb-5"
          style={{ width: "100%" }}
        >
          <thead className="align-middle sticky-header">
            <tr>
              <th className="text-center border-dark border-top fw-500 py-1 column-5 small">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={allSelected}
                  disabled={false}
                  onChange={(e) => checkAll(e)}
                />
              </th>
              <th
                className={`border-dark border-top fw-500 py-2 px-4 column-15 ${
                  sortBy.orderBy === "userName"
                    ? "sorted-" + sortBy.criteria
                    : ""
                }`}
                key="userName"
                id="UserName"
                onClick={() =>
                  dispatch(
                    changeOrder({
                      orderBy: "userName",
                      dataType: "string",
                    })
                  )
                }
              >
                Name
              </th>
              <th
                className={`text-center border-dark border-top border-right fw-500 py-2 px-4 column-15 ${
                  sortBy.orderBy === "status" ? "sorted-" + sortBy.criteria : ""
                }`}
                key="status"
                id="Status"
                onClick={() =>
                  dispatch(
                    changeOrder({
                      orderBy: "status",
                      dataType: "string",
                    })
                  )
                }
              >
                Status
              </th>
              <th
                className={`border-dark border-top border-right fw-500 py-2 column-25 text-center ${
                  sortBy.orderBy === "startDate"
                    ? "sorted-" + sortBy.criteria
                    : ""
                }`}
                key="startDate"
                id="Dates"
                onClick={() =>
                  dispatch(
                    changeOrder({
                      orderBy: "startDate",
                      dataType: "string",
                    })
                  )
                }
              >
                Start Date - End Date
              </th>
              <th
                className="border-dark border-top border-right fw-500 py-2 column-25 text-center"
                key="billableHours"
                id="Hours worked"
              >
                <p>Hours worked</p>
                <div className="d-flex justify-content-evenly">
                  <p
                    className={`helper-title me-1 ${
                      sortBy.orderBy === "billableHours"
                        ? "sorted-" + sortBy.criteria
                        : ""
                    }`}
                    onClick={() =>
                      dispatch(
                        changeOrder({
                          orderBy: "billableHours",
                          dataType: "number",
                        })
                      )
                    }
                  >
                    Client Billable
                  </p>
                  <p
                    className={`helper-title me-1 ${
                      sortBy.orderBy === "nonBillableHours"
                        ? "sorted-" + sortBy.criteria
                        : ""
                    }`}
                    onClick={() =>
                      dispatch(
                        changeOrder({
                          orderBy: "nonBillableHours",
                          dataType: "number",
                        })
                      )
                    }
                  >
                    Client Non-Billable
                  </p>
                  <p
                    className={`helper-title ${
                      sortBy.orderBy === "internalHours"
                        ? "sorted-" + sortBy.criteria
                        : ""
                    }`}
                    onClick={() =>
                      dispatch(
                        changeOrder({
                          orderBy: "internalHours",
                          dataType: "number",
                        })
                      )
                    }
                  >
                    Internal
                  </p>
                </div>
              </th>
              <th
                className={`border-dark border-top border-right fw-500 py-2 column-10 text-center ${
                  sortBy.orderBy === "workOrderHours"
                    ? "sorted-" + sortBy.criteria
                    : ""
                }`}
                onClick={() =>
                  dispatch(
                    changeOrder({
                      orderBy: "workOrderHours",
                      dataType: "number",
                    })
                  )
                }
                key="workOrderHours"
                id="Work Order hours"
              >
                Work Orders{" "}
                <Tooltip
                  title={
                    <b>Time is approved when the Work Order is Resolved</b>
                  }
                  placement="top"
                  TransitionComponent={Zoom}
                  arrow
                >
                  <span>
                    <BiInfoCircle className="disc-icon ms-1" />
                  </span>
                </Tooltip>
              </th>
              <th
                className={`border-dark border-top fw-500 py-2 column-15 text-center ${
                  sortBy.orderBy === "pendingHours"
                    ? "sorted-" + sortBy.criteria
                    : ""
                }`}
                onClick={() =>
                  dispatch(
                    changeOrder({
                      orderBy: "pendingHours",
                      dataType: "number",
                    })
                  )
                }
                id="Total hours"
                key="pendingHours"
              >
                Total hours
              </th>
            </tr>
          </thead>
          <tbody>
            {userListToShow &&
              userListToShow.map((user: UserToApprove) => {
                return (
                  <tr key={user.userId} style={{ verticalAlign: "middle" }}>
                    <td className="text-center py-0 border-dark">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={isSelected(user)}
                        disabled={false}
                        onChange={() => updatedSelected(user)}
                      />
                    </td>
                    <td className="py-3 ps-4 fw-500 border-dark">
                      <div className="td-text">{user.userName}</div>
                    </td>
                    <td className="py-3 text-center fw-500 border-dark border-right">
                      <div className={`td-status ${user.status} status-width`}>
                        {user.status}
                        {user.status === LOCKED && (
                          <BiLockAlt
                            className="ms-1"
                            style={{ width: "16px", height: "16px" }}
                          />
                        )}
                      </div>
                    </td>
                    <td className="py-3 ps-2 fw-500 border-dark border-right">
                      <div className="d-flex justify-content-evenly">
                        <div className="td-text">
                          {dateFormatEntryEdit(user.startDate)}
                        </div>
                        <div className="td-text">-</div>
                        <div className="td-text">
                          {dateFormatEntryEdit(user.endDate)}
                        </div>
                      </div>
                    </td>
                    <td className="py-3 ps-2 fw-500 border-dark border-right">
                      <div className="d-flex justify-content-around">
                        <div
                          className={`${
                            user.billableHours > 0 ? "td-task-link" : "td-text"
                          }`}
                          onClick={() =>
                            user.billableHours > 0
                              ? handleClick(user, "billableHours")
                              : null
                          }
                        >
                          {quantityFormat(user.billableHours)}
                        </div>
                        <div
                          className={`${
                            user.nonBillableHours > 0
                              ? "td-task-link"
                              : "td-text"
                          }`}
                          onClick={() =>
                            user.nonBillableHours > 0
                              ? handleClick(user, "nonBillableHours")
                              : null
                          }
                        >
                          {quantityFormat(user.nonBillableHours)}
                        </div>
                        <div
                          className={`${
                            user.internalHours > 0 ? "td-task-link" : "td-text"
                          }`}
                          onClick={() =>
                            user.internalHours > 0
                              ? handleClick(user, "internalHours")
                              : null
                          }
                        >
                          {quantityFormat(user.internalHours)}
                        </div>
                      </div>
                    </td>
                    <td className="py-3 pe-3 fw-500 border-dark border-right text-center">
                      <div
                        className={`${
                          user.workOrderHours > 0 ? "td-task-link" : "td-text"
                        }`}
                        onClick={() =>
                          user.workOrderHours > 0
                            ? handleClick(user, "workOrderHours")
                            : null
                        }
                      >
                        {quantityFormat(user.workOrderHours)}
                      </div>
                    </td>
                    <td className="py-3 ps-3 fw-500 border-dark">
                      <div className="d-flex">
                        <div
                          className={`me-2 ${
                            user.pendingHours > 0 ? "td-task-link" : "td-text"
                          }`}
                          onClick={() =>
                            user.pendingHours > 0
                              ? handleClick(user, "pendingHours")
                              : null
                          }
                        >
                          {quantityFormat(user.pendingHours)}
                        </div>
                        <div className="td-text me-2">/</div>
                        <div className="td-text me-1">
                          {quantityFormat(user.totalHours)}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </div>
  );
};

import { useNavigate, useParams } from "react-router-dom";
import {
  ROUTE_DELETE_PRODUCT_PAGE,
  ROUTE_PRODUCT_KEYCONTACTS_DETAILS,
} from "../../SharedModule/utils/constants";
import {
  getAdvocacyString,
  transformNameWithoutComma,
  getRelationshipTrend,
} from "../utils/keyContactUtils";
import { useKeyContacts } from "../hooks/useKeyContacts";
import { Spinner } from "../../SharedModule/components/Spinner";
import { KeyContact } from "../types/types";
import { useDispatch, useSelector } from "react-redux";
import {
  changeOrder,
  selectOrderCriteria,
  setIsLoadingData,
} from "../redux/reducers/keyContact.reducer";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";
import { setIsLoadingData as setIsLoadingDataSpotlight } from "../redux/reducers/spotlight.reducer";
import { setIsLoadingData as setIsLoadingDataSuccessCriteria } from "../redux/reducers/successCriteria.reducer";
import { setActiveTab } from "../redux/reducers/ui.reducer";
import image from "./../../styles/legacy/no-key-contacts.png";
import { NoContent } from "../../SharedModule/components/NoContent";
import HeaderModule from "../components/HeaderModule";
import Footer from "../../SharedModule/components/Footer";
import { selectAuth } from "../../SharedModule/redux/reducers/auth.reducer";

export const KeyContactsPage = () => {
  let { productId }: any = useParams();

  const { trackPageView } = useMatomo();

  const auth = useSelector(selectAuth);
  const isLogged = auth.isAuthenticated;

  const dispatch = useDispatch();
  const sortBy = useSelector(selectOrderCriteria);

  const {
    actualProductName,
    isContentEditable,
    isProductActive,
    keyContactList,
    isLoadingData,
    keyContacts,
  } = useKeyContacts(productId);

  const navigate = useNavigate();

  // ASUITE-11:2119: endpoint error when product not exist
  if (!isLoadingData && keyContactList?.errors) {
    if (keyContactList.errors[1] === "Product not found") {
      navigate(ROUTE_DELETE_PRODUCT_PAGE);
    }
  }

  const onClickHandler = (productId: string, id: string) => {
    navigate(
      ROUTE_PRODUCT_KEYCONTACTS_DETAILS.replace(
        ":productId",
        productId
      ).replace(":contactId", id)
    );
  };

  const takeToAddPage = () => {
    navigate(
      ROUTE_PRODUCT_KEYCONTACTS_DETAILS.replace(
        ":productId",
        productId
      ).replace(":contactId", "addContact")
    );
  };

  const columnHeaders: Array<any> = [
    {
      key: "contactName",
      type: "string",
      title: "Contact Name",
      columnSize: 20,
    },
    {
      key: "contactRoles",
      type: "string",
      title: "Role",
      columnSize: 10,
    },
    {
      key: "confidenceLevel",
      type: "number",
      title: "Confidence Value",
      columnSize: 15,
    },
    {
      key: "advocacyLevel",
      type: "string",
      title: "Relationship Level",
      columnSize: 15,
    },
    {
      key: "discProfile",
      type: "string",
      title: "DISC Profile",
      columnSize: 15,
    },
    {
      key: "relationshipSteward",
      type: "string",
      title: "Relationship Steward",
      columnSize: 20,
    },
  ];

  useEffect(() => {
    dispatch(setActiveTab("keyContacts"));
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
    dispatch(setIsLoadingData(true));
    dispatch(setIsLoadingDataSpotlight(true));
    dispatch(setIsLoadingDataSuccessCriteria(true));

    dispatch(
      changeOrder({
        orderBy: "contactName",
        dataType: "string",
        criteria: "asc",
      })
    );
  }, []);

  const getPageHeader = () => {
    return (
      <div className="content-header">
        <div className="content-header-title">
          {actualProductName} - Key Contacts{" "}
          <span className="count-amount">
            ( {keyContactList.length} contacts )
          </span>
          {!isProductActive && (
            <button className="btn-status">Status: Inactive</button>
          )}
        </div>
        {isLogged && isContentEditable && isProductActive && (
          <button className="btn btn-primary" onClick={() => takeToAddPage()}>
            Add New Contact
          </button>
        )}
      </div>
    );
  };

  return (
    <div className="content">
      <HeaderModule />
      {isLoadingData && (
        <Spinner
          style={{
            margin: "15% 45%",
          }}
        />
      )}
      {!isLoadingData && (
        <>
          {(!keyContacts || keyContacts.length === 0) && (
            <>
              {getPageHeader()}
              <NoContent
                title="No Key Contacts in this product yet."
                image={image}
                style={{ marginTop: "3%" }}
              />
            </>
          )}

          {keyContacts && keyContacts.length > 0 && (
            <>
              {getPageHeader()}
              <table className="table grey-table-header-background mt-5">
                <thead className="align-middle sticky sticky-header">
                  <tr>
                    {columnHeaders.map((column: any) => {
                      if (column.key !== "contactRoles") {
                        return (
                          <th
                            key={column.key}
                            className={`border-dark border-top ${
                              column.key !== "relationshipSteward"
                                ? "text-center"
                                : "ps-4"
                            } column-${column.columnSize} ${
                              sortBy.orderBy === column.key
                                ? "sorted-" + sortBy.criteria
                                : ""
                            }`}
                            onClick={() =>
                              dispatch(
                                changeOrder({
                                  orderBy: column.key,
                                  dataType: column.type,
                                })
                              )
                            }
                          >
                            <span>{column.title}</span>
                          </th>
                        );
                      } else {
                        return (
                          <th
                            key={column.key}
                            className={`border-dark border-top text-center column-${column.columnSize}`}
                          >
                            <span>{column.title}</span>
                          </th>
                        );
                      }
                    })}
                  </tr>
                </thead>
                <tbody>
                  {keyContacts.map((keyContact: KeyContact) => {
                    return (
                      <tr
                        key={keyContact.keyContactId}
                        className="keyContactRow product-link"
                        onClick={() =>
                          onClickHandler(
                            productId.toString(),
                            keyContact.keyContactId.toString()
                          )
                        }
                      >
                        <td className="key-contact pointer vert-middle td-shadow-effect">
                          <div className="key-contact-name-rol ps-3">
                            <div className="key-contact-name">
                              {transformNameWithoutComma(
                                keyContact.contactName
                              )}
                              {!keyContact.contactActive && (
                                <span>
                                  <i> - (Inactive)</i>
                                </span>
                              )}
                            </div>
                            <div className="key-contact-title">
                              {keyContact.contactTitle}
                            </div>
                          </div>
                        </td>
                        <td className="vert-middle td-shadow-effect">
                          <div className="d-flex flex-column">
                            {keyContact.contactRoles?.map((role: string) => (
                              <span
                                key={keyContact.keyContactId + role}
                                className="key-contact-role-block fs-small mb-1"
                              >
                                {role}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td className="vert-middle td-shadow-effect">
                          {keyContact.confidenceLevel > 0 && (
                            <span className="key-contact-role-block">
                              {keyContact.confidenceLevel}
                            </span>
                          )}
                        </td>
                        <td className="vert-middle td-shadow-effect">
                          {keyContact.advocacyLevel &&
                            keyContact.advocacyLevel !== "N/A" && (
                              <span
                                className={`key-contact-role-block ${getAdvocacyString(
                                  keyContact.advocacyLevel
                                )}`}
                              >
                                {getAdvocacyString(keyContact.advocacyLevel)}
                                {getRelationshipTrend(
                                  keyContact.relationshipTrend
                                ) && (
                                  <span className="trend-arrow">
                                    {getRelationshipTrend(
                                      keyContact.relationshipTrend
                                    )}
                                  </span>
                                )}
                              </span>
                            )}
                        </td>
                        <td className="vert-middle td-shadow-effect">
                          {keyContact.discProfile && (
                            <span className="key-contact-profile-block">
                              {keyContact.discProfile}
                            </span>
                          )}
                        </td>
                        <td className="key-contact vert-middle td-shadow-effect">
                          {keyContact.relationshipSteward && (
                            <div className="key-contact-name-rol">
                              <div className="key-contact-name">
                                {transformNameWithoutComma(
                                  keyContact.relationshipSteward
                                )}
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </>
      )}
      <Footer />
    </div>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TimesheetsService } from "../../services/timesheets";
import { SelectItem, UserAssignees } from "../../../types";
import {
  Client,
  NonBillableReason,
  ProjectManagerOrUser,
  ProjectOrWo,
  TaskType,
} from "../../types";
import { UsersService } from "../../../SharedModule/services/users";

const addElementToArray = (arrayToAdd: any, arrayToLoop: any) => {
  arrayToLoop.forEach((elem) => {
    arrayToAdd.push({
      value: elem.id,
      label: elem.name,
      level: elem.level,
    });
    if (elem.children && elem.children.length > 0) {
      addElementToArray(arrayToAdd, elem.children);
    }
  });
};

export const fetchMetaTimes = async (id: string, isTimeAdmin: boolean) => {
  let userAssignees,
    taskTypesData,
    nonBillableReasonsData,
    filtersData,
    approvalTimeData,
    costCentersData,
    costCentersApprovalTime;
  if (isTimeAdmin) {
    [
      userAssignees,
      taskTypesData,
      nonBillableReasonsData,
      filtersData,
      approvalTimeData,
    ] = await Promise.all([
      UsersService.getUserAssignees(id),
      TimesheetsService.getTaskTypes(),
      TimesheetsService.getNonBillableReasons(),
      TimesheetsService.getQueryFilters(),
      TimesheetsService.getApprovalTimeFilters(),
    ]);

    costCentersData = [];
    costCentersApprovalTime = [];
    if (filtersData && filtersData.data.costCenters) {
      addElementToArray(costCentersData, filtersData.data.costCenters);
    }
    if (approvalTimeData && approvalTimeData.data.costCenters) {
      addElementToArray(
        costCentersApprovalTime,
        approvalTimeData.data.costCenters
      );
    }
  } else {
    [userAssignees, taskTypesData, nonBillableReasonsData, filtersData] =
      await Promise.all([
        UsersService.getUserAssignees(id),
        TimesheetsService.getTaskTypes(),
        TimesheetsService.getNonBillableReasons(),
        TimesheetsService.getQueryFilters(),
      ]);

    costCentersData = [];
    costCentersApprovalTime = null;
    if (filtersData && filtersData.data.costCenters) {
      addElementToArray(costCentersData, filtersData.data.costCenters);
    }
  }

  return {
    userAssignees:
      (userAssignees &&
        userAssignees.data.map((assignee: UserAssignees) => ({
          value: assignee.id,
          label: assignee.fullName,
        }))) ||
      [],
    taskTypes:
      (taskTypesData &&
        taskTypesData.data.map((task: TaskType) => ({
          value: task.taskTypeId,
          label: task.taskTypeName,
          category: task.category,
        }))) ||
      [],
    nonBillableReasons:
      (nonBillableReasonsData &&
        nonBillableReasonsData.data.map((elem: NonBillableReason) => ({
          value: elem.id,
          label: elem.name,
        }))) ||
      [],
    costCenters: costCentersData || null,
    clients:
      (filtersData.data &&
        filtersData.data.clients.map((elem: Client) => ({
          value: elem.id,
          label: elem.name,
        }))) ||
      null,
    timeProjects:
      (filtersData.data &&
        filtersData.data.projects.map((elem: ProjectOrWo) => ({
          value: elem.entityId,
          label:
            elem.entityId > 0
              ? "Project " + elem.entityId + " - " + elem.entityName
              : elem.entityName,
          entityId: elem.entityId,
          categoryId: elem.categoryId,
          clientId: elem.clientId,
        }))) ||
      null,
    projectManagers:
      (filtersData.data &&
        filtersData.data.managers.map((elem: ProjectManagerOrUser) => ({
          value: elem.id,
          label: elem.fullName,
          projectIds: elem.projectIds,
        }))) ||
      null,
    timeUsers:
      (filtersData.data &&
        filtersData.data.timeUsers.map((elem: ProjectManagerOrUser) => ({
          value: elem.id,
          label: elem.fullName,
          isActive: elem.isActive,
        }))) ||
      null,
    countries:
      (filtersData.data &&
        filtersData.data.countries.map((elem: string) => ({
          value: elem,
          label: elem,
        }))) ||
      null,
    costCentersApprovalTime: costCentersApprovalTime || null,
    projectsApprovalTime:
      (approvalTimeData && approvalTimeData.data &&
        approvalTimeData.data.projects.map((elem: any) => ({
          value: elem.entityId,
          label: elem.entityName,
          categoryId: elem.categoryId,
        }))) ||
      null,
    managersApprovalTime:
      (approvalTimeData && approvalTimeData.data &&
        approvalTimeData.data.managers.map((elem: ProjectManagerOrUser) => ({
          value: elem.id,
          label: elem.fullName,
          projectIds: elem.projectIds,
        }))) ||
      null,
  };
};

export type MetaTimesState = {
  userAssignees: Array<SelectItem> | null;
  taskTypes: Array<any> | null;
  nonBillableReasons: Array<any> | null;
  costCenters: Array<any> | null;
  clients: Array<any> | null;
  timeProjects: Array<any> | null;
  projectManagers: Array<any> | null;
  timeUsers: Array<any> | null;
  countries: Array<any> | null;
  costCentersApprovalTime: Array<any> | null;
  projectsApprovalTime: Array<any> | null;
  managersApprovalTime: Array<any> | null;
};

type SetItem = Partial<MetaTimesState>;

const initialState: MetaTimesState = {
  userAssignees: null,
  taskTypes: null,
  nonBillableReasons: null,
  costCenters: null,
  clients: null,
  timeProjects: null,
  projectManagers: null,
  timeUsers: null,
  countries: null,
  costCentersApprovalTime: null,
  projectsApprovalTime: null,
  managersApprovalTime: null,
};

export const metaSlice = createSlice({
  name: "metaTimes",
  initialState,
  reducers: {
    // Remember Redux Toolkit allows us to write "mutating" logic in reducers.
    setMetaTimes: (state: MetaTimesState, action: PayloadAction<SetItem>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setMetaTimes } = metaSlice.actions;

// Selectors
export const selectUserAssignees = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.userAssignees;

export const selectTaskTypes = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.taskTypes;

export const selectNonBillableReasons = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.nonBillableReasons;

export const selectCostCenters = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.costCenters;

export const selectClients = ({ metaTimes }: { metaTimes: MetaTimesState }) => metaTimes.clients;

export const selectTimeProjects = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.timeProjects;

export const selectProjectManagers = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.projectManagers;

export const selecttimeUsers = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.timeUsers;

export const selectCountries = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.countries;

export const selectCostCentersApprovalTime = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.costCentersApprovalTime;

export const selectProjectsApprovalTime = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.projectsApprovalTime;

export const selectManagersApprovalTime = ({ metaTimes }: { metaTimes: MetaTimesState }) =>
  metaTimes.managersApprovalTime;

export const selectMetaTimesData = (state: { metaTimes: MetaTimesState }) => {
  return {
    userAssignees: selectUserAssignees(state),
    taskTypes: selectTaskTypes(state),
    nonBillableReasons: selectNonBillableReasons(state),
    costCenters: selectCostCenters(state),
    clients: selectClients(state),
    timeProjects: selectTimeProjects(state),
    projectManagers: selectProjectManagers(state),
    timeUsers: selecttimeUsers(state),
    countries: selectCountries(state),
    costCentersApprovalTime: selectCostCentersApprovalTime(state),
    projectsApprovalTime: selectProjectsApprovalTime(state),
    managersApprovalTime: selectManagersApprovalTime(state),
  };
};

export default metaSlice.reducer;

import { EntryHoursBox } from "../components/EntryHoursBox";
import {
  concatProjectsWorkOrders,
  empty,
  emptyProjectWorkOrder,
  getProjectWorkOrder,
  getValueFromSelect,
} from "../utils/timesUtils";
import { selectMetaTimesData } from "../redux/reducers/metaTimes.reducer";
import { useSelector } from "react-redux";
import { newUuid } from "../../SharedModule/utils/uuid";
import { TimeEntryForm } from "../types";
import { useEntryHourBox } from "../hooks/useEntryHourBox";
import { Spinner } from "../../SharedModule/components/Spinner";
import { billableIds } from "../../SharedModule/utils/constants";

export const ModalTimeEntry = (props: any) => {
  const MetaTimesInfo = useSelector(selectMetaTimesData);

  // Should use concat, when we need to find a specific project
  const actualProjectsWorkOrders = concatProjectsWorkOrders(props.projectList);

  const { isLoading, selectProjectOrWorkOrder } = useEntryHourBox(
    props.userIdParam
  );

  let concatedSelectProjectOrWorkOrder = concatProjectsWorkOrders(
    selectProjectOrWorkOrder
  );

  const getSpecificProjects = () => {
    return props.isQueryPage
      ? concatedSelectProjectOrWorkOrder
      : actualProjectsWorkOrders;
  };

  const getProjects = () => {
    return props.isQueryPage ? selectProjectOrWorkOrder : props.projectList;
  };

  // function to wait projects loaded
  const findProjectWorkOrder = () => {
    let specificProjects = getSpecificProjects();
    return props.editMode
      ? getProjectWorkOrder(specificProjects, props.entry.entityId)
      : props.entryInitalInfo
      ? getProjectWorkOrder(specificProjects, props.entryInitalInfo.entityId)
      : emptyProjectWorkOrder;
  };

  let elem: TimeEntryForm = {
    // should combine entry canEdit with status pending (timeEntryStatusId === 1) to allow to edit
    entryCanEdit: props.editMode
      ? props.entry.canEdit && props.entry.timeEntryStatusId === 1
      : true,
    entryCanDelete: props.editMode ? props.entry.canDelete : true,
    entryId: props.editMode ? props.entry.timeEntryId : newUuid(),
    entryDate: props.editMode
      ? props.entryInitalInfo
        ? props.entryInitalInfo.modalDate
        : props.entry.entryDate
      : props.entryInitalInfo
      ? props.entryInitalInfo.modalDate
      : new Date().toISOString(),
    entryDateValid: true,
    entryProjectWorkOrder: findProjectWorkOrder(),
    entryProjectWorkOrderValid:
      props.editMode ||
      (props.entryInitalInfo && props.entryInitalInfo.entityId)
        ? true
        : false,
    entryHours: props.editMode ? props.entry.hours : "",
    entryHoursValid: props.editMode,
    entryTaskType: props.editMode
      ? getValueFromSelect(MetaTimesInfo.taskTypes, props.entry.taskTypeId)
      : props.entryInitalInfo
      ? props.entryInitalInfo.taskType
        ? getValueFromSelect(
            MetaTimesInfo.taskTypes,
            props.entryInitalInfo.taskType
          )
        : props.entryInitalInfo.entryTaskType
        ? props.entryInitalInfo.entryTaskType
        : empty
      : empty,
    entryTaskTypeValid:
      props.editMode ||
      (props.entryInitalInfo && props.entryInitalInfo.taskType)
        ? true
        : false,
    entryDescription: props.editMode ? props.entry.description : "",
    entryIsBillable: props.editMode
      ? billableIds.includes(
          props.entry.timeEntryTypeId.toString().toLowerCase()
        )
      : true,
    entryNonBillableReason:
      props.editMode && props.entry.nonBillableReasonId
        ? getValueFromSelect(
            MetaTimesInfo.nonBillableReasons,
            props.entry.nonBillableReasonId
          )
        : empty,
    entryNonBillableReasonValid:
      props.editMode && props.entry.nonBillableReasonId ? true : false,
    isEntryValid: false,
    isEntryTouched: false,
  };

  return (
    <>
      {isLoading && (
        <Spinner
          style={{
            marginLeft: "50%",
          }}
        />
      )}
      {!isLoading && (
        <EntryHoursBox
          title={props.editMode ? "Edit time entry" : "Add time"}
          state={elem}
          btnCancel={props.btnCancel}
          projectsWorkOrders={getProjects()}
          isLoadingProjects={isLoading}
          isModal={true}
          editMode={props.editMode}
          userIdParam={props.userIdParam}
          reloadPage={props.reloadPage}
          resetPage={props.resetPage}
          holidays={props.holidays}
          multipleTimeEntries={
            props.actualTimeEntries && props.actualTimeEntries.length > 1
              ? props.actualTimeEntries
              : null
          }
          deleteEntry={props.deleteEntry}
          timeEntriesList={props.timeEntriesList}
          isQueryPage={props.isQueryPage}
          isHolidayFromBamboo={props.isHolidayFromBamboo}
        />
      )}
    </>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { groupByProperty } from "../../utils/timesUtils";

type TimesState = {
  isAdmin: boolean;
  isLoading: boolean;
  summary: {
    total: string;
    billable: string;
    nonBillable: string;
    internal: string;
    timeOff: string;
  };
  queryResultList: object;
};

const initialState: TimesState = {
  isAdmin: false,
  isLoading: true,
  summary: {
    total: "—",
    billable: "—",
    nonBillable: "—",
    internal: "—",
    timeOff: "—",
  },
  queryResultList: {},
};

export const queryResultsSlice = createSlice({
  name: "queryResults",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadQueryResultsInfo: (state, action) => {
      state.summary = action.payload.summary;
      // set some IDs to help super admin to select/unselect multiples
      let i = 1;
      action.payload.result.forEach((element) => {
        element.id = i;
        i += 1;
        // to avoid issue when editing from results page
        element.entryDate = element.entryDate.split("T")[0] + "T03:00:00Z";
      });
      state.queryResultList = groupByProperty(
        action.payload.result,
        "entryDate"
      );
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, loadQueryResultsInfo } = queryResultsSlice.actions;

// Selectors
export const IsLoading = (state: any) => {
  return state.queryResults.isLoading;
};

export const Summary = (state: any) => {
  return state.queryResults.summary;
};

export const QueryResultList = (state: any) => {
  return state.queryResults.queryResultList;
};

export default queryResultsSlice.reducer;

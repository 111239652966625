import { getHostUrl } from "../../ProductsModule/utils/reportsUtils";
import { TelerikReport } from "../components/TelerikReport";

export const AccessControlReportByUser = () => {

  let parameters = {
    HostUrl: getHostUrl()
  };

  return (
    <TelerikReport
      title="Access Control Report By User"
      reportName="AccessControlReportByUser.trdp"
      parameters={parameters}
      parametersAreaVisible={true}
    />
  );
};

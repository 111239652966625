import { TelerikReportViewer } from "@progress/telerik-react-report-viewer";
import { useEffect, useState } from "react";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import Footer from "./Footer";
import eventHandler from "../utils/eventHandler";
import { getServiceUrl } from "../../ProductsModule/utils/reportsUtils";
import { Spinner } from "../../SharedModule/components/Spinner";

export const TelerikReport = ({
  title,
  reportName,
  parameters,
  parametersAreaVisible,
  parametersEditor,
}: {
  title: string;
  reportName: string;
  parameters?: any;
  parametersAreaVisible: boolean;
  parametersEditor?: any;
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setTimeout(() => {
        setIsloading(false);
      }, 1000);
    };

    eventHandler.on("toggleLeftNavBarFixed", () => {
      setIsloading(true);
      handleResize();
    });
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenWidth]);

  const { trackPageView } = useMatomo();

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
  }, []);

  const styles = {
    position: "absolute",
    left: "5px",
    right: "5px",
    top: "60px",
    bottom: "5px",
    overflow: "hidden",
    clear: "both",
    margin: "0 auto 5%",
    height: "93vh",
    width: screenWidth > 1440 ? "80%" : "90%",
  };

  return (
    <div className="content" style={{ height: "110vh" }}>
      <div className="button-header">
        <h2>{title}</h2>
      </div>

      {isLoading && (
        <div className="mt-5">
          <Spinner
            style={{
              marginLeft: "50%",
              marginTop: "5%",
            }}
          />
        </div>
      )}

      {!isLoading && (
        <TelerikReportViewer
          serviceUrl={getServiceUrl()}
          reportSource={{
            report: reportName,
            parameters: parameters ? parameters : null,
          }}
          parameters={parametersEditor ? parametersEditor : null}
          viewerContainerStyle={styles}
          viewMode="INTERACTIVE"
          scaleMode="SPECIFIC"
          scale={0.8}
          enableAccessibility={false}
          sendEmail={{ enabled: false }}
          parametersAreaVisible={parametersAreaVisible}
        />
      )}

      <Footer isReport={true} />
    </div>
  );
};

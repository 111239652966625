import { BiLeftArrowAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ROUTE_PRODUCTS_LIST,
  ROUTE_PRODUCT_KEYCONTACTS,
  ROUTE_PRODUCT_SPOTLIGHT,
  ROUTE_PRODUCT_SUCCESSCRITERIA,
} from "../../SharedModule/utils/constants";
import { setIsLoadingProducts } from "../redux/reducers/products.reducer";
import { useSpotlights } from "../hooks/useSpotlights";
import { TabLabel } from "./../../BillingModule/components/TabLabel";
import { CheckSkeleton } from "../../SharedModule/utils/checkSkeleton";

export default function HeaderModule() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { productId } = useParams();
  const { actualProductName, isLoadingData } = useSpotlights(Number(productId));

  let id = productId ? productId : "";

  const backToProductList = () => {
    dispatch(setIsLoadingProducts(true));
    navigate(ROUTE_PRODUCTS_LIST);
  };

  const checkActiveTab = (tab: string) => {
    return location.pathname.endsWith(tab);
  };

  const onClickHandler = (route: string, id: string) => {
    navigate(route.replace(":productId", id));
  };

  return (
    <div className="header-module-container">
      <div
        className="back-button-container"
        onClick={() => backToProductList()}
      >
        <BiLeftArrowAlt className="back-button-arrow" />
        <span className="back-button-text">Back to Product List</span>
      </div>
      <CheckSkeleton
        isLoading={isLoadingData}
        style={{ width: "40%", lineHeight: "25px", marginTop: "5px" }}
      >
        <div className="header-module-title">
          Product: {actualProductName}
        </div>
      </CheckSkeleton>
      <div className="d-flex flex-row mt-3">
        <TabLabel
          key="spotlight"
          active={checkActiveTab("spotlight")}
          classes="top-nav-tab"
          onClick={() => onClickHandler(ROUTE_PRODUCT_SPOTLIGHT, id)}
        >
          <div className="header-module-link">Spotlight</div>
        </TabLabel>
        <TabLabel
          key="keyContacts"
          active={checkActiveTab("keyContacts")}
          classes="top-nav-tab"
          onClick={() => onClickHandler(ROUTE_PRODUCT_KEYCONTACTS, id)}
        >
          <div className="header-module-link">Key Contacts</div>
        </TabLabel>
        <TabLabel
          key="successCriteria"
          active={checkActiveTab("successCriteria")}
          classes="top-nav-tab"
          onClick={() => onClickHandler(ROUTE_PRODUCT_SUCCESSCRITERIA, id)}
        >
          <div className="header-module-link">Success Criteria</div>
        </TabLabel>
      </div>
      <hr className="header-separator" />
    </div>
  );
}

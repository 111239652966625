import { useSelector } from "react-redux";
import { selectAuth } from "../../SharedModule/redux/reducers/auth.reducer";
import { getHostUrl } from "../utils/reportsUtils";
import { TelerikReport } from "../../SharedModule/components/TelerikReport";

export const AdvocacySummaryReportPage = () => {
  const auth = useSelector(selectAuth);
  let parameters = {
    HostUrl: getHostUrl(),
    Auth: auth.accessToken,
  };

  return (
    <TelerikReport
      title="Advocacy Summary Report"
      reportName="AdvocacySummary.trdp"
      parameters={parameters}
      parametersAreaVisible={false}
    />
  );
};

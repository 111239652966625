import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect, useState } from "react";
import Footer from "../../SharedModule/components/Footer";
import { Select } from "../../SharedModule/components/Select";
import { Calendar } from "../../SharedModule/components/Calendar";
import { useDispatch } from "react-redux";
import { BiInfoCircle } from "react-icons/bi";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { Checkbox } from "../../SharedModule/components/Checkbox";
import {
  checkDataIsLoaded,
  getLastWeekDay,
  getNextWeekDay,
  getPrevSunday,
  getProjectOrWorkOrderGroupedBy,
} from "../utils/timesUtils";
import {
  ALL_PROJECTS_AND_WORK_ORDERS,
  LAST_MONTH,
  LAST_WEEK,
  ROUTE_QUERY_RESULTS_TIMESHEETS,
  ROUTE_QUERY_TIMESHEETS,
  THIS_MONTH,
  THIS_WEEK,
} from "../../SharedModule/utils/constants";
import { useNavigate } from "react-router-dom";
import {
  setFilters,
  setAdditionalFilters,
  keepFilters,
} from "../redux/reducers/timesQuery.reducer";
import { ProjectOrWo } from "../types";
import { useQueryPage } from "../hooks/useQueryPage";
import { setActiveTab } from "../../ProductsModule/redux/reducers/ui.reducer";
import eventHandler from "../../SharedModule/utils/eventHandler";

export const TimesQueryPage = () => {
  const { trackPageView } = useMatomo();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const {
    MetaTimesInfo,
    filters,
    isShowAdditionalFilters,
    initialStatefilters,
    isTimeAdmin,
  } = useQueryPage();

  const activeUsers = MetaTimesInfo.timeUsers?.filter((user) => user.isActive);

  // ASUITE11-2373: remove Benefits(8), Budget Exceeded(10), Unpaid Time Off(9) and ITX Investment: Internal Project(4) from the Non-Billable Reason dropdown
  const nonBillableReasonsAfterFilter =
    MetaTimesInfo.nonBillableReasons?.filter(
      (reason) => ![8, 10, 9, 4].includes(reason.value)
    );

  // avoid issue from reload page in query page, projects in filters endpoint take 2 seconds
  let projectsInCache: any = localStorage.getItem("metaTimeProjects");
  let actualProjects =
    MetaTimesInfo.timeProjects && MetaTimesInfo.timeProjects.length > 0
      ? MetaTimesInfo.timeProjects
      : JSON.parse(projectsInCache);

  const [timeProjectsWithAllLogic, setTimeProjectsWithAllLogic] = useState<any>(
    getProjectOrWorkOrderGroupedBy(actualProjects)
  );

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    // should go in every times page
    if (!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)) {
      eventHandler.dispatch("loadTimesStaticInfo", { isTimeAdmin: isTimeAdmin});
    }

    dispatch(setActiveTab(ROUTE_QUERY_TIMESHEETS));

    return () => {
      // reset filters if user got to another page
      if (window.location.pathname !== ROUTE_QUERY_RESULTS_TIMESHEETS) {
        dispatch(keepFilters(false));
        dispatch(setAdditionalFilters(false));
      }
    };
  }, [trackPageView, isTimeAdmin]);

  const today = new Date();
  // should use a huge object
  const [pageFilters, setPageFilters] = useState({
    // Filters
    startDate: filters.startDate,
    endDate: filters.endDate,
    costCenters:
      MetaTimesInfo.costCenters?.length === 1
        ? [MetaTimesInfo.costCenters[0]]
        : filters.costCenters,
    clients:
      MetaTimesInfo.clients?.length === 1
        ? [MetaTimesInfo.clients[0]]
        : filters.clients,
    projects: filters.projects,
    projectManagers:
      MetaTimesInfo.projectManagers?.length === 1
        ? [MetaTimesInfo.projectManagers[0]]
        : filters.projectManagers,
    users:
      MetaTimesInfo.timeUsers?.length === 1
        ? [MetaTimesInfo.timeUsers[0]]
        : filters.users,
    // aditional filters
    includeInactiveUsers: filters.includeInactiveUsers,
    taskTypes:
      MetaTimesInfo.taskTypes?.length === 1
        ? [MetaTimesInfo.taskTypes[0]]
        : filters.taskTypes,
    countries:
      MetaTimesInfo.countries?.length === 1
        ? [MetaTimesInfo.countries[0]]
        : filters.countries,
    hourType: {
      billableHours: filters.hourType.billableHours,
      nonBillableHours: filters.hourType.nonBillableHours,
      internalHours: filters.hourType.internalHours,
    },
    nonBillableReasons: filters.nonBillableReasons,
    timeOff: {
      paidTimeOff: filters.timeOff.paidTimeOff,
      unpaidTimeOff: filters.timeOff.unpaidTimeOff,
    },
    approvalStatus: {
      pendingHours: filters.approvalStatus.pendingHours,
      approvedHours: filters.approvalStatus.approvedHours,
      lockedHours: filters.approvalStatus.lockedHours,
    },
  });

  const [showAdditionalFilters, setShowAdditionalFilters] = useState(
    isShowAdditionalFilters
  );

  const updateDates = (option: string) => {
    switch (option) {
      case THIS_WEEK:
        setPageFilters({
          ...pageFilters,
          startDate: getPrevSunday(today).toISOString(),
          endDate: getNextWeekDay(6, today).toISOString(),
        });
        break;
      case LAST_WEEK:
        setPageFilters({
          ...pageFilters,
          startDate: getLastWeekDay(7, today).toISOString(),
          endDate: getLastWeekDay(1, today).toISOString(),
        });
        break;
      case THIS_MONTH:
        setPageFilters({
          ...pageFilters,
          startDate: new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          ).toISOString(),
          endDate: new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
          ).toISOString(),
        });
        break;
      case LAST_MONTH:
        setPageFilters({
          ...pageFilters,
          startDate: new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            1
          ).toISOString(),
          endDate: new Date(
            today.getFullYear(),
            today.getMonth(),
            0
          ).toISOString(),
        });
        break;
      default:
        break;
    }
  };

  const resetAditionalFilters = () => {
    setPageFilters({
      ...pageFilters,
      includeInactiveUsers: false,
      taskTypes:
        MetaTimesInfo.taskTypes?.length === 1
          ? [MetaTimesInfo.taskTypes[0]]
          : null,
      countries:
        MetaTimesInfo.countries?.length === 1
          ? [MetaTimesInfo.countries[0]]
          : null,
      hourType: {
        billableHours: true,
        nonBillableHours: true,
        internalHours: true,
      },
      nonBillableReasons: null,
      timeOff: {
        paidTimeOff: true,
        unpaidTimeOff: false,
      },
      approvalStatus: {
        pendingHours: true,
        approvedHours: true,
        lockedHours: true,
      },
    });
  };

  const toggleShowAdditionalsFilters = () => {
    if (!showAdditionalFilters) {
      resetAditionalFilters();
    }
    setShowAdditionalFilters(!showAdditionalFilters);
    dispatch(setAdditionalFilters(!showAdditionalFilters));
  };

  const resetFiltersPage = () => {
    setPageFilters(initialStatefilters);
  };

  const runQuery = () => {
    if (!showAdditionalFilters) {
      dispatch(
        setFilters({
          ...pageFilters,
          includeInactiveUsers: false,
          taskTypes: null,
          countries: null,
          hourType: {
            billableHours: true,
            nonBillableHours: true,
            internalHours: true,
          },
          nonBillableReasons: null,
          timeOff: {
            paidTimeOff: true,
            unpaidTimeOff: false,
          },
          approvalStatus: {
            pendingHours: true,
            approvedHours: true,
            lockedHours: true,
          },
        })
      );
    } else {
      dispatch(setFilters(pageFilters));
    }
    navigate(ROUTE_QUERY_RESULTS_TIMESHEETS);
  };

  const sendNullIfEmpty = (actualArray: any[]) => {
    return actualArray.length > 0 ? actualArray : null;
  };

  const handleProjectChange = (project: ProjectOrWo[]) => {
    let allProjects = project.find((elem) => elem.entityId === -2);
    let allWorkOrders = project.find((elem) => elem.entityId === -3);

    if (allProjects && allWorkOrders) {
      setTimeProjectsWithAllLogic(
        MetaTimesInfo.timeProjects?.find((elem) => elem.entityId === 0)
      );
      let aux = project.filter((elem) => elem.entityId < 0);
      setPageFilters({ ...pageFilters, projects: aux });
    } else if (allProjects) {
      setTimeProjectsWithAllLogic(
        getProjectOrWorkOrderGroupedBy(
          MetaTimesInfo.timeProjects?.filter((elem) => elem.categoryId !== 1)
        )
      );
      let aux = project.filter((elem) => elem.categoryId !== 1);
      setPageFilters({ ...pageFilters, projects: aux });
    } else if (allWorkOrders) {
      setTimeProjectsWithAllLogic(
        getProjectOrWorkOrderGroupedBy(
          MetaTimesInfo.timeProjects?.filter((elem) => elem.categoryId !== 3)
        )
      );
      let aux = project.filter((elem) => elem.categoryId !== 3);
      setPageFilters({ ...pageFilters, projects: aux });
    } else {
      setTimeProjectsWithAllLogic(
        getProjectOrWorkOrderGroupedBy(MetaTimesInfo.timeProjects)
      );
      setPageFilters({ ...pageFilters, projects: sendNullIfEmpty(project) });
    }
  };

  const handleChangeHourType = (option: number) => {
    // 1-billableHours, 2-nonBillableHours, 2-internalHours
    // At least one selected
    if (
      (option === 1 &&
        pageFilters.hourType.billableHours &&
        !pageFilters.hourType.nonBillableHours &&
        !pageFilters.hourType.internalHours) ||
      (option === 2 &&
        !pageFilters.hourType.billableHours &&
        pageFilters.hourType.nonBillableHours &&
        !pageFilters.hourType.internalHours) ||
      (option === 3 &&
        !pageFilters.hourType.billableHours &&
        !pageFilters.hourType.nonBillableHours &&
        pageFilters.hourType.internalHours)
    ) {
      return;
    }
    setPageFilters({
      ...pageFilters,
      hourType: {
        billableHours:
          option === 1
            ? !pageFilters.hourType.billableHours
            : pageFilters.hourType.billableHours,
        nonBillableHours:
          option === 2
            ? !pageFilters.hourType.nonBillableHours
            : pageFilters.hourType.nonBillableHours,
        internalHours:
          option === 3
            ? !pageFilters.hourType.internalHours
            : pageFilters.hourType.internalHours,
      },
      nonBillableReasons:
        option === 2
          ? !pageFilters.hourType.nonBillableHours
            ? pageFilters.nonBillableReasons
            : null
          : pageFilters.nonBillableReasons,
    });
  };

  const handleChangeApprovalStatus = (option: number) => {
    // 1-pendingHours, 2-approvedHours, 2-lockedHours
    // At least one selected
    if (
      (option === 1 &&
        pageFilters.approvalStatus.pendingHours &&
        !pageFilters.approvalStatus.approvedHours &&
        !pageFilters.approvalStatus.lockedHours) ||
      (option === 2 &&
        !pageFilters.approvalStatus.pendingHours &&
        pageFilters.approvalStatus.approvedHours &&
        !pageFilters.approvalStatus.lockedHours) ||
      (option === 3 &&
        !pageFilters.approvalStatus.pendingHours &&
        !pageFilters.approvalStatus.approvedHours &&
        pageFilters.approvalStatus.lockedHours)
    ) {
      return;
    }
    setPageFilters({
      ...pageFilters,
      approvalStatus: {
        pendingHours:
          option === 1
            ? !pageFilters.approvalStatus.pendingHours
            : pageFilters.approvalStatus.pendingHours,
        approvedHours:
          option === 2
            ? !pageFilters.approvalStatus.approvedHours
            : pageFilters.approvalStatus.approvedHours,
        lockedHours:
          option === 3
            ? !pageFilters.approvalStatus.lockedHours
            : pageFilters.approvalStatus.lockedHours,
      },
    });
  };

  return (
    <div className="content">
      <div className="d-flex flex-wrap">
        <h2 className="flex-fill">Query</h2>
      </div>
      <form className="card entry-hour-box-background p-4 mt-3">
        <div className="row justify-content-center">
          <label className="col-sm-3 col-form-label">Time Frame</label>
          <div className="col-sm-5">
            <div className="d-flex">
              <div className="position-relative">
                <label className="form-label">From</label>
                <Calendar
                  date={pageFilters.startDate}
                  onChange={(date: string) =>
                    setPageFilters({ ...pageFilters, startDate: date })
                  }
                />
              </div>
              <div className="position-relative ms-5">
                <label className="form-label">To</label>
                <Calendar
                  date={pageFilters.endDate}
                  onChange={(date: string) =>
                    setPageFilters({ ...pageFilters, endDate: date })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="mt-2 offset-sm-5 col-sm-7">
            <div
              className="btn button-secondary"
              onClick={() => updateDates(THIS_WEEK)}
            >
              {THIS_WEEK}
            </div>
            <div
              className="btn button-secondary ms-2"
              onClick={() => updateDates(LAST_WEEK)}
            >
              {LAST_WEEK}
            </div>
            <div
              className="btn button-secondary ms-2"
              onClick={() => updateDates(THIS_MONTH)}
            >
              {THIS_MONTH}
            </div>
            <div
              className="btn button-secondary ms-2"
              onClick={() => updateDates(LAST_MONTH)}
            >
              {LAST_MONTH}
            </div>
          </div>
        </div>

        <div className="row mt-4 justify-content-center">
          <label className="col-sm-3 col-form-label">
            Cost Center
            <Tooltip
              title={
                <b>Time for users that are assigned to this Cost Center</b>
              }
              placement="right"
              TransitionComponent={Zoom}
              arrow
            >
              <span>
                <BiInfoCircle className="disc-icon ms-1" />
              </span>
            </Tooltip>
          </label>
          <div className="col-sm-5">
            <Select
              isMulti
              placeholder="All"
              options={MetaTimesInfo.costCenters}
              value={pageFilters.costCenters}
              onChange={(costCenter) =>
                setPageFilters({
                  ...pageFilters,
                  costCenters: sendNullIfEmpty(costCenter),
                })
              }
              isDisabled={!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)}
            />
          </div>
        </div>

        <div className="row mt-3 justify-content-center">
          <label className="col-sm-3 col-form-label">Clients</label>
          <div className="col-sm-5">
            <Select
              isMulti
              placeholder="All"
              options={MetaTimesInfo.clients}
              value={pageFilters.clients}
              onChange={(client) =>
                setPageFilters({
                  ...pageFilters,
                  clients: sendNullIfEmpty(client),
                })
              }
              isDisabled={!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)}
            />
          </div>
        </div>

        <div className="row mt-3 justify-content-center">
          <label className="col-sm-3 col-form-label">Projects</label>
          <div className="col-sm-5">
            <Select
              isMulti
              placeholder={ALL_PROJECTS_AND_WORK_ORDERS}
              options={timeProjectsWithAllLogic}
              value={pageFilters.projects}
              onChange={(project) => handleProjectChange(project)}
              isDisabled={!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)}
            />
          </div>
        </div>

        <div className="row mt-3 justify-content-center">
          <label className="col-sm-3 col-form-label">
            Project Manager
            <Tooltip
              title={
                <b>
                  Time in projects that are assigned to this Project Manager
                </b>
              }
              placement="right"
              TransitionComponent={Zoom}
              arrow
            >
              <span>
                <BiInfoCircle className="disc-icon ms-1" />
              </span>
            </Tooltip>
          </label>
          <div className="col-sm-5">
            <Select
              isMulti
              placeholder="All"
              options={MetaTimesInfo.projectManagers}
              value={pageFilters.projectManagers}
              onChange={(manager) =>
                setPageFilters({
                  ...pageFilters,
                  projectManagers: sendNullIfEmpty(manager),
                })
              }
              isDisabled={!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)}
            />
          </div>
        </div>

        <div className="row mt-3 justify-content-center">
          <label className="col-sm-3 col-form-label">Users</label>
          <div className="col-sm-5">
            <Select
              isMulti
              placeholder="All"
              options={
                pageFilters.includeInactiveUsers
                  ? MetaTimesInfo.timeUsers
                  : activeUsers
              }
              value={pageFilters.users}
              onChange={(user) =>
                setPageFilters({ ...pageFilters, users: sendNullIfEmpty(user) })
              }
              isDisabled={!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-sm-5 offset-sm-5">
            <div
              className="link-text px-0"
              onClick={() => toggleShowAdditionalsFilters()}
            >
              {showAdditionalFilters ? "Hide" : "Show"} additional filter
              options
              {showAdditionalFilters ? " (reset filters and close)" : ""}
            </div>
          </div>
        </div>

        {showAdditionalFilters && (
          <>
            {isTimeAdmin && (
              <div className="row justify-content-center mt-4">
                <label className="col-sm-3 col-form-label"></label>
                <div className="col-sm-5">
                  <Checkbox
                    label="Include inactive users"
                    value={pageFilters.includeInactiveUsers}
                    onClickHandler={() =>
                      setPageFilters({
                        ...pageFilters,
                        includeInactiveUsers: !pageFilters.includeInactiveUsers,
                      })
                    }
                  />
                </div>
              </div>
            )}

            <div className="row mt-4 justify-content-center">
              <label className="col-sm-3 col-form-label">Task Type</label>
              <div className="col-sm-5">
                <Select
                  isMulti
                  placeholder="All"
                  options={MetaTimesInfo.taskTypes}
                  value={pageFilters.taskTypes}
                  onChange={(taskType) =>
                    setPageFilters({
                      ...pageFilters,
                      taskTypes: sendNullIfEmpty(taskType),
                    })
                  }
                  isDisabled={!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)}
                />
              </div>
            </div>

            <div className="row mt-3 justify-content-center">
              <label className="col-sm-3 col-form-label">
                Country
                <Tooltip
                  title={<b>Time for users that are located in this Country</b>}
                  placement="right"
                  TransitionComponent={Zoom}
                  arrow
                >
                  <span>
                    <BiInfoCircle className="disc-icon ms-1" />
                  </span>
                </Tooltip>
              </label>
              <div className="col-sm-5">
                <Select
                  isMulti
                  placeholder="All"
                  options={MetaTimesInfo.countries}
                  value={pageFilters.countries}
                  onChange={(country) =>
                    setPageFilters({
                      ...pageFilters,
                      countries: sendNullIfEmpty(country),
                    })
                  }
                  isDisabled={!checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)}
                />
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              <label className="col-sm-3 col-form-label">Hour Type</label>
              <div className="col-sm-5">
                <Checkbox
                  label="Billable hours"
                  value={pageFilters.hourType.billableHours}
                  onClickHandler={() => handleChangeHourType(1)}
                />
                <Checkbox
                  className="mt-2"
                  label="Non-Billable hours"
                  value={pageFilters.hourType.nonBillableHours}
                  onClickHandler={() => handleChangeHourType(2)}
                />
                <Checkbox
                  className="mt-2"
                  label="Internal hours"
                  value={pageFilters.hourType.internalHours}
                  onClickHandler={() => handleChangeHourType(3)}
                />
              </div>
            </div>

            <div className="row mt-4 justify-content-center">
              <label className="col-sm-3 col-form-label">
                Non-Billable Reason
              </label>
              <div className="col-sm-5">
                <Select
                  isMulti
                  placeholder="All"
                  options={nonBillableReasonsAfterFilter}
                  value={pageFilters.nonBillableReasons}
                  onChange={(nonBillableReason) =>
                    setPageFilters({
                      ...pageFilters,
                      nonBillableReasons: sendNullIfEmpty(nonBillableReason),
                    })
                  }
                  isDisabled={!pageFilters.hourType.nonBillableHours || !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)}
                />
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              <label className="col-sm-3 col-form-label">Time Off</label>
              <div className="col-sm-5">
                <Checkbox
                  label="Paid time off"
                  value={pageFilters.timeOff.paidTimeOff}
                  onClickHandler={() =>
                    setPageFilters({
                      ...pageFilters,
                      timeOff: {
                        paidTimeOff: !pageFilters.timeOff.paidTimeOff,
                        unpaidTimeOff: pageFilters.timeOff.unpaidTimeOff,
                      },
                    })
                  }
                />
                <Checkbox
                  className="mt-2"
                  label="Unpaid time off"
                  value={pageFilters.timeOff.unpaidTimeOff}
                  onClickHandler={() =>
                    setPageFilters({
                      ...pageFilters,
                      timeOff: {
                        paidTimeOff: pageFilters.timeOff.paidTimeOff,
                        unpaidTimeOff: !pageFilters.timeOff.unpaidTimeOff,
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              <label className="col-sm-3 col-form-label">Approval Status</label>
              <div className="col-sm-5">
                <Checkbox
                  label="Pending hours"
                  value={pageFilters.approvalStatus.pendingHours}
                  onClickHandler={() => handleChangeApprovalStatus(1)}
                />
                <Checkbox
                  className="mt-2"
                  label="Approved hours"
                  value={pageFilters.approvalStatus.approvedHours}
                  onClickHandler={() => handleChangeApprovalStatus(2)}
                />
                <Checkbox
                  className="mt-2"
                  label="Locked hours"
                  value={pageFilters.approvalStatus.lockedHours}
                  onClickHandler={() => handleChangeApprovalStatus(3)}
                />
              </div>
            </div>

            <div className="row justify-content-center mt-4">
              <label className="col-sm-3 col-form-label">
                Optional Summaries
              </label>
              <div className="col-sm-5">
                <Checkbox
                  label="Summary by User"
                  value={false}
                  onClickHandler={() => console.log("change checkbox")}
                />
                <Checkbox
                  className="mt-2"
                  label="Summary by Task"
                  value={false}
                  onClickHandler={() => console.log("change checkbox")}
                />
                <Checkbox
                  className="mt-2"
                  label="Summary by Task Type"
                  value={false}
                  onClickHandler={() => console.log("change checkbox")}
                />
                <Checkbox
                  className="mt-2"
                  label="Hide individual entries"
                  value={false}
                  onClickHandler={() => console.log("change checkbox")}
                  isDisabled={true || !checkDataIsLoaded(MetaTimesInfo, isTimeAdmin)}
                />
              </div>
            </div>
          </>
        )}

        <hr className="separator" />
        <div className="mt-4 footer-filter">
          <div
            className="link-text"
            onClick={() => {
              resetFiltersPage();
            }}
          >
            Reset Filters
          </div>
          <button className="btn btn-primary" onClick={() => runQuery()}>
            Run Query
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
};

import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getLastWeekDay } from "../../utils/timesUtils";
import { isTodayBetween1stAnd3rdBusinessDay } from "../../../SharedModule/utils/dateUtils";
import { NOW } from "../../../SharedModule/utils/constants";
import { UserToApprove } from "../../types";
import { sortDataBy } from "../../../SharedModule/utils/dataSort";

type ApprovalTimeState = {
  isLoading: boolean;
  filters: {
    projectOwners: Array<any> | null;
    costCenters: Array<any> | null;
    projects: Array<any> | null;
    billableTypes: Array<any> | null;
    endDate: string;
  };
  usersList: UserToApprove[] | null;
  orderedBy: {
    orderBy: string;
    criteria: "asc" | "desc";
    dataType?: "string" | "date" | "number";
  };
};

const today = new Date();

const getEndDate = () => {
  return isTodayBetween1stAnd3rdBusinessDay()
    ? NOW.minus({ month: 1 }).endOf("month").startOf("day").toISO()
    : getLastWeekDay(1, today).toISOString();
};

const initialState: ApprovalTimeState = {
  isLoading: true,
  filters: {
    projectOwners: null,
    costCenters: null,
    projects: null,
    billableTypes: null,
    endDate: getEndDate(),
  },
  usersList: [],
  orderedBy: {
    orderBy: "userName",
    criteria: "asc",
    dataType: "string",
  },
};

export const approvalTimeSlice = createSlice({
  name: "approvalTime",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    loadTimeEntriesInfo: (state, action) => {
      state.usersList = action.payload;
      state.isLoading = false;
    },
    changeOrder: (
      state,
      action: PayloadAction<{
        orderBy: string;
        dataType?: "string" | "date" | "number";
        criteria?: "asc" | "desc";
      }>
    ) => {
      const newOrderedBy: any = {
        ...action.payload,
        criteria: action.payload.criteria ? action.payload.criteria : "asc",
      };
      if (newOrderedBy.orderBy === state.orderedBy.orderBy) {
        newOrderedBy.criteria = action.payload.criteria
          ? action.payload.criteria
          : state.orderedBy.criteria === "asc"
          ? "desc"
          : "asc";
      }
      return { ...state, orderedBy: newOrderedBy };
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, setFilters, loadTimeEntriesInfo, changeOrder, resetFilters } =
  approvalTimeSlice.actions;

// Selectors
export const IsLoading = (state: any) => {
  return state.approvalTime.isLoading;
};

export const SelectFilters = (state: any) => {
  return state.approvalTime.filters;
};

export const SelectUserToApprove = (state: any) => {
  return state.approvalTime.usersList;
};

export const selectUserList = ({
  approvalTime,
}: {
  approvalTime: ApprovalTimeState;
}) => {
  return { usersList: approvalTime.usersList };
};

export const selectOrderCriteria = ({
  approvalTime,
}: {
  approvalTime: ApprovalTimeState;
}) => approvalTime.orderedBy;

export const selectOrderedBy = createSelector(
  [selectUserList, selectOrderCriteria],
  (approvalTime, orderCriteria) => {
    return {
      userList:
        (approvalTime.usersList &&
          approvalTime.usersList.length > 0 &&
          sortDataBy(
            approvalTime.usersList,
            orderCriteria.orderBy,
            orderCriteria.criteria,
            orderCriteria.dataType
          )) ||
        null,
    };
  }
);

export default approvalTimeSlice.reducer;

import { getHostUrl } from "../../ProductsModule/utils/reportsUtils";
import { TelerikReport } from "../components/TelerikReport";

export const AccessControlReportPageByGitlabProject = () => {

  let parameters = {
    HostUrl: getHostUrl()
  };
  
  return (
    <TelerikReport
      title="Access Control Report By Gitlab Project"
      reportName="AccessControlReportByGitlabProject.trdp"
      parameters={parameters}
      parametersAreaVisible={true}
    />
  );
};

import { useNavigate, useParams } from "react-router-dom";
import { useSpotlights } from "../hooks/useSpotlights";
import { Spinner } from "../../SharedModule/components/Spinner";
import { ROUTE_PRODUCT_SPOTLIGHT } from "../../SharedModule/utils/constants";
import { PaginatedItems } from "./../components/PaginatedItems";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";
import image from "./../../styles/legacy/spotlight-off.png";
import { NoContent } from "../../SharedModule/components/NoContent";
import { BiLeftArrowAlt } from "react-icons/bi";
import Footer from "../../SharedModule/components/Footer";

export const SpotlightHistoryPage = () => {
  const navigate = useNavigate();

  const { trackPageView } = useMatomo();

  let { productId } = useParams();

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
  }, []);

  const { spotlightHistory, isLoadingData, actualProductName } = useSpotlights(
    Number(productId)
  );

  const backHandler = (id: any) => {
    navigate(ROUTE_PRODUCT_SPOTLIGHT.replace(":productId", id));
  };

  const hasSpotlightHistory = () => {
    if (spotlightHistory && spotlightHistory.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <>
      {isLoadingData && (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      )}
      {!isLoadingData && (
        <div className="content">
          <div
            className="back-button-container"
            onClick={() => backHandler(productId)}
          >
            <BiLeftArrowAlt className="back-button-arrow" />
            <span className="back-button-text">Back to Spotlight</span>
          </div>
          <h2>{actualProductName} - Spotlight History</h2>
          {hasSpotlightHistory() && (
            <PaginatedItems
              itemsPerPage={10}
              items={spotlightHistory}
              type={"spotlight"}
            />
          )}
          {!hasSpotlightHistory() && (
            <>
              <NoContent
                title="No Spotlight History"
                image={image}
                style={{ margin: "5% 25% 0 25%" }}
              />
              <Footer />
            </>
          )}
        </div>
      )}
    </>
  );
};

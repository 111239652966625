import { getHostUrl } from "../../ProductsModule/utils/reportsUtils";
import { TelerikReport } from "../components/TelerikReport";

export const AccessControlReportPageByJiraProject = () => {

  let parameters = {
    HostUrl: getHostUrl()
  };
  
  return (
    <TelerikReport
      title="Access Control Report By Jira Project"
      reportName="AccessControlReportByJiraProject.trdp"
      parameters={parameters}
      parametersAreaVisible={true}
    />
  );
};

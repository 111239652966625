import { useEffect, useRef, useState } from "react";
import ReactCalendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import useOnClickOutside from "../hooks/useOnClickOutside";
import { BiCalendar } from "react-icons/bi";
import "./calendar.scss";
import styles from "./Calendar.module.scss";

export const Calendar = ({
  date,
  className = "",
  isDisabled = false,
  onChange: change = (date) => {},
  minDate = undefined,
  maxDate = undefined,
}) => {
  const [value, onChange] = useState(new Date());
  const [inputValue, setInputValue] = useState(new Date());
  const [visible, setVisible] = useState(false);
  const [focused, setFocused] = useState(false);
  const calendarRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    if (focused) return;
    const dateProp = new Date(date);
    onChange(dateProp);
    setInputValue(
      dateProp.toLocaleString("en-us", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })
    );
  }, [date, focused]);

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(calendarRef, (event) => {
    if (event.target !== inputRef.current) setVisible(false);
  });

  const onChangeCalendar = (date) => {
    setInputValue(
      date.toLocaleString("en-us", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      })
    );
    onChange(date);
    change(date.toISOString());
    setVisible(false);
  };

  const onChangeInput = (event) => {
    const dateString = event.target.value;
    let newDate = new Date(dateString);
    setInputValue(dateString);
    if (Date.parse(dateString)) {
      newDate = new Date(dateString);
      onChange(newDate);
      change(newDate.toISOString());
    }
  };

  const focusHandler = () => {
    setVisible(true);
    setFocused(true);
  };

  const blurHandler = () => {
    setFocused(false);
  };

  return (
    <div className={`position-relative ${className}`}>
      <input
        className="form-control form-control-sm calendar-input"
        type="text"
        name="calendar"
        onFocus={focusHandler}
        onBlur={blurHandler}
        onChange={onChangeInput}
        value={inputValue}
        ref={inputRef}
        disabled={isDisabled}
      />
      <BiCalendar
        className={styles.icon}
        style={{ color: "#6357BB", width: "18px", height: "18px" }}
        onClick={() => inputRef.current.focus()}
      />

      {visible && (
        <ReactCalendar
          onChange={onChangeCalendar}
          inputRef={calendarRef}
          minDetail={"year"}
          showNeighboringMonth={false}
          value={value}
          locale="en-US"
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
    </div>
  );
};

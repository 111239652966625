import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../../SharedModule/components/Spinner";
import { ROUTE_PRODUCT_KEYCONTACTS_DETAILS } from "../../SharedModule/utils/constants";
import { useKeyContacts } from "../hooks/useKeyContacts";
import { KeyContactsService } from "../services/keyContacts";
import { ConfidenceHistory } from "../types/types";
import { PaginatedItems } from "./../components/PaginatedItems";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { BiLeftArrowAlt } from "react-icons/bi";

export const ConfidenceHistoryPage = () => {
  const navigate = useNavigate();

  const { trackPageView } = useMatomo();

  let { productId, contactId } = useParams();

  let initialState: ConfidenceHistory[] = [];

  const [confidenceHistory, setConfidenceHistory] = useState(initialState);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [hasConfidenceHistory, setHasConfidenceHistory] = useState(false);

  const loadConfidenceHistory = async () => {
    let response = await KeyContactsService.getConfidendeHistory(
      Number(productId),
      Number(contactId)
    );
    if (response && response.length > 0) {
      setConfidenceHistory(response);
      setHasConfidenceHistory(true);
    }
    setIsLoadingData(false);
  };

  useEffect(() => {
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    loadConfidenceHistory();
  }, []);

  const { actualProductName } = useKeyContacts(Number(productId));

  const backHandler = (productId: any, contactId: any) => {
    navigate(
      ROUTE_PRODUCT_KEYCONTACTS_DETAILS.replace(
        ":productId",
        productId
      ).replace(":contactId", contactId)
    );
  };

  return (
    <>
      {isLoadingData && (
        <Spinner
          style={{
            marginLeft: "40%",
            marginTop: "5%",
          }}
        />
      )}
      {!isLoadingData && (
        <div className="content">
          <div
            className="back-button-container"
            onClick={() => backHandler(productId, contactId)}
          >
            <BiLeftArrowAlt className="back-button-arrow" />
            <span className="back-button-text">Back to Contact Details</span>
          </div>
          <h2>{actualProductName} - Stackholder Confidence History</h2>
          {hasConfidenceHistory && (
            <PaginatedItems
              itemsPerPage={10}
              items={confidenceHistory}
              type={"stackholder"}
            />
          )}
        </div>
      )}
    </>
  );
};

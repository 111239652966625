import {
  EntryListView,
  FiltersToApproveBody,
  RowSheetView,
  TimeEntryForm,
} from "./../types";
import { newUuid } from "../../SharedModule/utils/uuid";
import { FiltersBody, SelectItemNumber } from "../types";
import {
  ALL_PROJECTS_AND_WORK_ORDERS,
  PROJ_MEAL_BREAK_ID,
  PROJ_NURSING_MOTHER_ID,
  PROJ_PAID_TIME_OFF_ID,
  PROJ_UNPAID_TIME_OFF_ID,
  ROLE_PRICE_MANAGER,
  ROLE_PROJECT_OWNER,
  ROLE_SUPERVISOR,
  ROLE_SUPER_ADMIN,
  ROLE_TIMESHEETS_ADMIN,
  TASKTYPE_MEAL_BREAK_ID,
  TASKTYPE_NURSING_MOTHER_ID,
  TASKTYPE_UNPAID_TIME_OFF_ID,
} from "../../SharedModule/utils/constants";
import { quantityFormat } from "../../SharedModule/utils/formatters";
import { MetaTimesState } from "../redux/reducers/metaTimes.reducer";

export const empty: SelectItemNumber = {
  value: 0,
  label: "—",
};

export const hoursTypeOptions: SelectItemNumber[] = [
  {
    value: 1,
    label: "Client Billable",
  },
  {
    value: 2,
    label: "Client Non-billable",
  },
  {
    value: 3,
    label: "Internal",
  },
];

export const emptyProjectWorkOrder: SelectItemNumber = {
  value: 0,
  label: "Select a project, task or work order you are assigned to",
};

// Param is array of roles only for time module
export const hasTimeAdminAccess = (userRoles: string[]) => {
  let response: boolean = false;
  userRoles?.forEach((role: string) => {
    if (
      role === ROLE_SUPER_ADMIN ||
      role === ROLE_TIMESHEETS_ADMIN ||
      role === ROLE_SUPERVISOR ||
      role === ROLE_PROJECT_OWNER
    ) {
      response = true;
    }
  });
  return response;
};

// Param is array of roles only for time module
export const hasSuperTimeAdminAccess = (userRoles: string[]) => {
  let response: boolean = false;
  userRoles?.forEach((role: string) => {
    if (role === ROLE_SUPER_ADMIN || role === ROLE_TIMESHEETS_ADMIN) {
      response = true;
    }
  });
  return response;
};

export const isPriceManager = (userRoles: string[]) => {
  let response: boolean = false;
  userRoles?.forEach((role: string) => {
    if (role === ROLE_PRICE_MANAGER) {
      response = true;
    }
  });
  return response;
};

export const getEmptyTimeEntry = (day: Date) => {
  return {
    entryCanEdit: true,
    entryCanDelete: true,
    entryId: newUuid(),
    entryDate: day.toISOString(),
    entryDateValid: true,
    entryProjectWorkOrder: emptyProjectWorkOrder,
    entryProjectWorkOrderValid: false,
    entryHours: "",
    entryHoursValid: false,
    entryTaskType: empty,
    entryTaskTypeValid: false,
    entryDescription: "",
    entryIsBillable: true,
    entryNonBillableReason: empty,
    entryNonBillableReasonValid: false,
    isEntryValid: false,
    isEntryTouched: false,
  };
};

export const getCategoryId = (list: any, value: number) => {
  let selected = list.find((elem) => elem.entityId === value);
  return selected.categoryId;
};

export const getProjectWorkOrder = (list: any, value: number) => {
  let selected = list.find((elem) => elem.entityId === value);
  return selected;
};

export const getValueFromSelect = (list: any, value: number | null) => {
  let selected = list && list.find((elem) => elem.value === value);
  return selected;
};

export const getValueFromSelectWithLabel = (list: any, label: string) => {
  let selected = list.find((elem) => elem.label === label);
  return selected;
};

export const getPrevSunday = (pageDate: Date) => {
  let actualDate = new Date(
    pageDate.getFullYear(),
    pageDate.getMonth(),
    pageDate.getUTCDate(),
    3,
    0,
    0
  );
  return new Date(
    actualDate.setDate(actualDate.getUTCDate() - actualDate.getDay())
  );
};

// get next day from sunday
export const getNextWeekDay = (days: number, pageDate: Date) => {
  let sunday = getPrevSunday(pageDate);
  let actualDate = new Date(
    sunday.getFullYear(),
    sunday.getMonth(),
    sunday.getUTCDate(),
    3,
    0,
    0
  );
  return new Date(actualDate.setDate(actualDate.getUTCDate() + days));
};

// days will be number to discount
export const getLastWeekDay = (days: number, pageDate: Date) => {
  let sunday = getPrevSunday(pageDate);
  let actualDate = new Date(
    sunday.getFullYear(),
    sunday.getMonth(),
    sunday.getUTCDate(),
    3,
    0,
    0
  );
  return new Date(actualDate.setDate(actualDate.getUTCDate() - days));
};

export const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const firstLetterToLower = (word: string) => {
  return word.charAt(0).toLowerCase() + word.slice(1);
};

export const transformValue = (property: string, value: any) => {
  let response;

  switch (property) {
    case "costCenters":
    case "clients":
    case "projects":
    case "projectManagers":
    case "users":
    case "taskTypes":
    case "countries":
    case "nonBillableReasons":
    case "projectOwners":
    case "billableTypes":
      if (value === null) {
        if (property === "projects") {
          response = ALL_PROJECTS_AND_WORK_ORDERS;
        } else {
          response = "All " + camelCaseToWords(property);
        }
      } else {
        value.forEach((element) => {
          if (!response) {
            response = element.label;
          } else {
            response = response + ", " + element.label;
          }
        });
      }
      break;
    case "hourType":
    case "timeOff":
    case "approvalStatus":
      if (
        property === "timeOff" &&
        !value["paidTimeOff"] &&
        !value["unpaidTimeOff"]
      ) {
        response = "None";
      } else {
        for (const prop in value) {
          if (value[prop]) {
            if (!response) {
              response = camelCaseToWords(prop);
            } else {
              response = response + ", " + camelCaseToWords(prop);
            }
          }
        }
      }
      break;
    default:
      break;
  }
  return response;
};

export const preparedFilters = (filters: any) => {
  // projectManagers
  let projectManagerInfo = [];
  if (filters.projectManagers) {
    filters.projectManagers.forEach((proj) => {
      projectManagerInfo = projectManagerInfo.concat(proj.projectIds);
    });
  }
  // projects
  let projectsInfo: any[] = [];
  // send null id all project and all work orders are selected
  if (
    !(
      filters.projects &&
      filters.projects.length === 2 &&
      filters.projects.every((proj) => proj.value < 0)
    )
  ) {
    if (filters.projects) {
      filters.projects.forEach((proj) => {
        projectsInfo.push({
          categoryId: proj.categoryId,
          entityId: proj.entityId,
        });
      });
    }
  }
  // hourType
  let hourTypeInfo: number[] = [];
  if (filters.hourType.billableHours) hourTypeInfo.push(1);
  if (filters.hourType.nonBillableHours) hourTypeInfo.push(2);
  if (filters.hourType.internalHours) hourTypeInfo.push(3);
  // timeOff
  let timeOffInfo: number[] = [];
  if (filters.timeOff.paidTimeOff) timeOffInfo.push(1);
  if (filters.timeOff.unpaidTimeOff) timeOffInfo.push(2);
  // approvalStatus
  let approvalStatusInfo: number[] = [];
  if (filters.approvalStatus.pendingHours) approvalStatusInfo.push(1);
  if (filters.approvalStatus.approvedHours) approvalStatusInfo.push(2);
  if (filters.approvalStatus.lockedHours) approvalStatusInfo.push(3);

  let response: FiltersBody = {
    startDate: filters.startDate,
    endDate: filters.endDate,
    costCentersIds:
      filters.costCenters === null
        ? null
        : filters.costCenters.map((elem) => elem.value),
    clientIds:
      filters.clients === null
        ? null
        : filters.clients.map((elem) => elem.value),
    entities: projectsInfo.length > 0 ? projectsInfo : null,
    managersProjects:
      filters.projectManagers === null ? null : projectManagerInfo,
    userIds:
      filters.users === null ? null : filters.users.map((elem) => elem.value),
    taskTypeIds:
      filters.taskTypes === null
        ? null
        : filters.taskTypes.map((elem) => elem.value),
    countries:
      filters.countries === null
        ? null
        : filters.countries.map((elem) => elem.value),
    hourTypes: hourTypeInfo.length > 0 ? hourTypeInfo : null,
    nonBillableReasonsIds:
      filters.nonBillableReasons === null
        ? null
        : filters.nonBillableReasons.map((elem) => elem.value),
    timeOff: timeOffInfo.length > 0 ? timeOffInfo : null,
    statusIds: approvalStatusInfo.length > 0 ? approvalStatusInfo : null,
  };
  return response;
};

export const navigateTo = (entry: EntryListView) => {
  let url: string = "";
  switch (entry.categoryId) {
    case 1:
      //project
      url = `${process.env.REACT_APP_ASUITE_PROJECT_URI}${entry.entityId}`;
      break;
    case 2:
      // task
      url = `${process.env.REACT_APP_ASUITE_PROJECT_URI}${entry.projectId}`;
      break;
    default:
      // work order
      url = `${process.env.REACT_APP_ASUITE_WO_URI}${entry.entityId}`;
      break;
  }
  return url;
};

export const findEntityId = (structure: any) => {
  let responseId: number = 0;
  Object.keys(structure).forEach((key: string) => {
    if (structure[key].timeEntries.length > 0 && responseId === 0) {
      responseId = structure[key].timeEntries[0].entityId;
    }
  });
  return responseId;
};

export const navigateToFromSheetView = (entry: RowSheetView) => {
  let url: string = "";
  switch (entry.categoryId) {
    case 1:
      //project
      url = `${process.env.REACT_APP_ASUITE_PROJECT_URI}${entry.entityId}`;
      break;
    case 2:
      // task
      url = `${process.env.REACT_APP_ASUITE_PROJECT_URI}${entry.projectId}`;
      break;
    default:
      // work order
      let partialEntiryId: number = findEntityId(entry.dailyDetails);
      url = `${process.env.REACT_APP_ASUITE_WO_URI}${
        partialEntiryId ? partialEntiryId : entry.entityId
      }`;
      break;
  }
  return url;
};

// creates an object, filters by any property
export const groupByProperty = (array, property) => {
  let hash = {};

  for (let elem of array) {
    if (!hash[elem[property]]) hash[elem[property]] = [];
    hash[elem[property]].push(elem);
  }
  return hash;
};

// to avoid issue of 2 structures separated by categoryId
export const concatProjectsWorkOrders = (projectsWorkOrders) => {
  return projectsWorkOrders[0].options.concat(projectsWorkOrders[1].options);
};

const replaceKeyByCorrectName = (str) => {
  switch (str) {
    case "EntryDate":
      return "Date";
    case "Project":
      return "Project / Work order";
    case "NonBillableReason":
      return "Non-billable reason";
    case "TimeEntryType":
      return "Billable";
    case "TaskType":
      return "Task type";
    default:
      return str;
  }
};
export const formatAuditNewChanges = (oldValues: any, newValues: any) => {
  let response: string[] = [];

  for (const key in oldValues) {
    if (oldValues[key] !== newValues[key]) {
      response.push(
        `${replaceKeyByCorrectName(key)}: ${
          oldValues[key] ? oldValues[key] : "N/A"
        } → ${newValues[key] ? newValues[key] : "N/A"}`
      );
    }
  }
  return response;
};

// Sum of hours worked from diff subtask in call of project (sheet view)
export const sumHoursWorked = (dailyDetails: any[]) => {
  let sum = 0;
  dailyDetails.forEach((element) => {
    sum = sum + element.hoursWorked;
  });
  return quantityFormat(sum);
};

export const getCellClassTodayClickable = (
  condition1: any,
  condition2: any
) => {
  if (condition1 === condition2) {
    return "today-clickable";
  } else {
    return "time-entry-clickable";
  }
};

// To load select grouped by
export const getProjectOrWorkOrderGroupedBy = (structure: any) => {
  if (structure && structure.length > 0) {
    return [
      {
        label: "All",
        options: structure?.filter((item) => {
          if (item.categoryId < 0) {
            return item;
          }
          return null;
        }),
      },
      {
        label: "Projects",
        options: structure?.filter((item) => {
          if (item.categoryId === 1) {
            return item;
          }
          return null;
        }),
      },
    ];
  } else {
    return [];
  }
};

export const getTaskCategoryName = (value: number) => {
  switch (value) {
    case 1:
      return "Ordinary";
    case 2:
      return "Special";
    case 3:
      return "Time Off";
    default:
      return "";
  }
};

export const setTimeEntryTypeIdforRequest = (entry: TimeEntryForm) => {
  return entry.entryTaskType.value === TASKTYPE_NURSING_MOTHER_ID
    ? 4
    : entry.entryTaskType.value === TASKTYPE_UNPAID_TIME_OFF_ID ||
      entry.entryTaskType.value === TASKTYPE_MEAL_BREAK_ID
    ? 5
    : entry.entryIsBillable
    ? 1
    : 2;
};

export const checkMealBreakOrNursingMother = (dailyDetails: any[]) => {
  let response = false;
  Object.keys(dailyDetails).forEach((elem) => {
    if (dailyDetails[elem].timeEntries.length > 0) {
      response =
        dailyDetails[elem].timeEntries[0].entityId === PROJ_MEAL_BREAK_ID ||
        dailyDetails[elem].timeEntries[0].entityId === PROJ_NURSING_MOTHER_ID
          ? true
          : false;
    }
  });
  return response;
};

export const preparedFiltersToApprove = (filters: any) => {
  // projectManagers
  let projectManagerInfo = [];
  if (filters.projectOwners) {
    filters.projectOwners.forEach((proj) => {
      projectManagerInfo = projectManagerInfo.concat(proj.projectIds);
    });
  }
  // projects
  let projectsInfo: any[] = [];
  // send null id all project and all work orders are selected
  if (filters.projects) {
    filters.projects.forEach((proj) => {
      projectsInfo.push({
        categoryId: proj.categoryId,
        entityId: proj.value,
      });
    });
  }

  let response: FiltersToApproveBody = {
    endDate: filters.endDate,
    costCentersIds:
      filters.costCenters === null || filters.costCenters.length === 0
        ? null
        : filters.costCenters.map((elem) => elem.value),
    entities: projectsInfo.length > 0 ? projectsInfo : null,
    managersProjects:
      filters.projectOwners === null || filters.projectOwners.length === 0
        ? null
        : projectManagerInfo,
    hourTypes:
      filters.billableTypes === null || filters.billableTypes.length === 0
        ? null
        : filters.billableTypes.map((elem) => elem.value),
  };
  return response;
};

// This function replace property "isLoaded" from times recuder
// TODO: review prop "isLoaded" in others meta reducers
export const checkDataIsLoaded = (
  state: MetaTimesState,
  isTimeAdmin: boolean
) => {
  return !!(
    state.userAssignees &&
    state.taskTypes &&
    state.nonBillableReasons &&
    state.costCenters &&
    state.clients &&
    state.timeProjects &&
    state.projectManagers &&
    state.timeUsers &&
    state.countries &&
    (!isTimeAdmin ||
      (isTimeAdmin &&
        state.costCentersApprovalTime &&
        state.projectsApprovalTime &&
        state.managersApprovalTime))
  );
};

export const isEntryTimeOff = (day: EntryListView) => {
  return (
    day.entityId === PROJ_PAID_TIME_OFF_ID ||
    day.entityId === PROJ_UNPAID_TIME_OFF_ID
  );
};

export const isEntryTimeOffValid = (day: EntryListView) => {
  return (
    day.entityId === PROJ_PAID_TIME_OFF_ID ||
    (day.entityId === PROJ_UNPAID_TIME_OFF_ID &&
      day.taskTypeId === TASKTYPE_UNPAID_TIME_OFF_ID)
  );
};

export const checkEntryTimeOffForUserOrAdmin = (
  entry: EntryListView,
  activeUserId: any
) => {
  // if entry is not time off or if time off but user is not actual user
  return (
    !isEntryTimeOffValid(entry) ||
    (isEntryTimeOffValid(entry) && activeUserId !== entry.userId)
  );
};

export const getCorrectHoursType = (hoursType: string) => {
  switch (hoursType) {
    case "billableHours":
      return "Billable Hours";
    case "nonBillableHours":
      return "Non Billable Hours";
    case "internalHours":
      return "Internal Hours";
    case "workOrderHours":
      return "Work Order Hours";
    case "pendingHours":
      return "All Pending Hours";
    default:
      break;
  }
};

export const isObjectEmpty = (objectName) => {
  return (
    Object.keys(objectName).length === 0 && objectName.constructor === Object
  );
};

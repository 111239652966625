import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IsLoading,
  SelectProjectOrWorkOrder,
  loadEntryHourBoxInfo,
  setLoading,
} from "../redux/reducers/entryHourBox.reducer";
import { TimesheetsService } from "../services/timesheets";
import { quantityFormat } from "../../SharedModule/utils/formatters";

// showedWeek param is pass only for call get endpoint once a work week change
export const useEntryHourBox = (userId: any) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(IsLoading);
  const selectProjectOrWorkOrder = useSelector(SelectProjectOrWorkOrder);

  const loadEntryBoxInfo = useCallback(
    async (userId: string) => {
      dispatch(setLoading(true));
      if (userId){
          let projectsWorkOrders = await TimesheetsService.getUserProjects(userId);
    
          const partial = projectsWorkOrders.data.map((elem) => ({
            value: elem.entityId,
            label: elem.showBudget
              ? elem.name +
                " (" +
                quantityFormat(elem.budgetRemaining) +
                " budget hours remaining)"
              : elem.name,
            categoryId: elem.categoryId,
            entityId: elem.entityId,
            isInternal: elem.isInternal,
            defaultTaskTypeId: elem.defaultTaskTypeId,
            taskTypesCategory: elem.taskTypesCategory
          }));
          const info = {
            projectList: partial,
          };
          dispatch(loadEntryHourBoxInfo(info));
      } else {
        dispatch(setLoading(false));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    loadEntryBoxInfo(userId);
  }, [loadEntryBoxInfo, userId]);

  return {
    isLoading,
    selectProjectOrWorkOrder,
    loadEntryBoxInfo,
  };
};

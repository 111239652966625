import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Arrow } from "./Arrow";
import { Badge } from "../../../SharedModule/components/Badge";
import { Select } from "../../../SharedModule/components/Select";
import { Tooltip } from "../../../SharedModule/components/Tooltip";
import { selectFilters } from "./../../redux/reducers/invoices.reducer";
import { BillingService } from "../../services/billing";
import { Invoice, SelectItem } from "../../../types";
import {
  PENDING,
  ROLE_BILLING_MODULE_PERFORM_ACTION,
  ROUTE_INVOICES_DETAIL,
  ROUTE_INVOICES_LIST,
} from "../../../SharedModule/utils/constants";
import {
  activityLogFormat,
  dateFormatWithHours,
} from "../../../SharedModule/utils/formatters";
import { actions } from "../InvoicesPage/MoreActionsButton";
import { InvoiceActions } from "./InvoiceActions";
import { InvoiceBody } from "./InvoiceBody";
import { InvoiceFooter } from "./InvoiceFooter";
import { InvoiceHeader } from "./InvoiceHeader";
import { NavHashLink } from "react-router-hash-link";
import { useInvoices } from "../../hooks/useInvoices";
import { AccessControl } from "../../../SharedModule/components/AccessControl";
import { BiLeftArrowAlt } from "react-icons/bi";

export const Detail = ({
  invoice,
  users,
  updateInvoice,
  changeOwner,
  sendReminders,
  loadInvoice,
}: {
  invoice: Invoice;
  users: SelectItem[] | null;
  updateInvoice: (
    callback: Function,
    action: actions,
    id?: number
  ) => Promise<void>;
  changeOwner: Function;
  sendReminders: Function;
  loadInvoice: Function;
}) => {
  const navigate = useNavigate();
  const filtersState = useSelector(selectFilters);
  const [prevInvoiceId, setPrevInvoiceId] = useState<number | null>(null);
  const [nextInvoiceId, setNextInvoiceId] = useState<number | null>(null);

  // Avoid show as last activity comments and QB number changes
  const lastActivity = invoice.activityLogs.find((activity) => {
    return (
      activity.activityType !== "InvoiceCommentAdded" &&
      activity.activityType !== "InvoiceQBNumberChanged"
    );
  });

  const { getFiltersData } = useInvoices(false);

  const loadList = useCallback(async () => {
    const { id } = invoice;

    let data = getFiltersData();
    const invoices: Invoice[] = await BillingService.searchInvoices(data);

    const currentInvoiceIndex = invoices
      ?.sort((a, b) => a.id - b.id)
      .findIndex((invoice) => invoice.id === id);
    const firstHalf = invoices?.slice(0, currentInvoiceIndex);
    const prev = firstHalf.pop()?.id || null;
    const next = invoices?.slice(currentInvoiceIndex + 1).shift()?.id || null;

    setPrevInvoiceId(prev);
    setNextInvoiceId(next);
  }, [filtersState, invoice]);

  useEffect(() => {
    setPrevInvoiceId(null);
    setNextInvoiceId(null);
    loadList();
  }, [loadList]);

  const getTitle = (qbNumber: string | null) => {
    if (!qbNumber) return `Invoice ${invoice.id}`;
    return (
      <>
        Invoice {invoice.id} (
        <Tooltip message="This is the QuickBooks ID.">{qbNumber}</Tooltip>)
      </>
    );
  };

  const goToInvoice = async (id: number) => {
    const route = ROUTE_INVOICES_DETAIL.replace(":id", id.toString());
    navigate(route);
  };

  return (
    <div>
      <div className="my-2">
        <div
          className="back-button-container"
          onClick={() => navigate(ROUTE_INVOICES_LIST)}
        >
          <BiLeftArrowAlt className="back-button-arrow" />
          <span className="back-button-text">Back to Invoices</span>
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <div className="d-flex">
          <h2>{getTitle(invoice.quickBooksNumber)}</h2>
          <Badge
            className="align-self-baseline mt-1 ms-2"
            status={invoice.status.name}
            isFullWidth={false}
          />
        </div>

        <div className="flex-shrink-1 invoice-navigate">
          <button
            className={
              "btn invoice-navigate-left" + (!prevInvoiceId ? " disabled" : "")
            }
            onClick={() => prevInvoiceId && goToInvoice(prevInvoiceId)}
          >
            <Arrow type="left" color="#626262" />
          </button>
          <button
            className={
              "btn invoice-navigate-right" + (!nextInvoiceId ? " disabled" : "")
            }
            onClick={() => nextInvoiceId && goToInvoice(nextInvoiceId)}
          >
            <Arrow type="right" color="#626262" />
          </button>
        </div>
      </div>

      {lastActivity && (
        <div>
          <span className="fw-bold">Latest Activity: </span>
          {lastActivity.activityType !== "InvoiceStatusChanged" &&
            activityLogFormat(lastActivity.activityType)}
          {lastActivity.activityType === "InvoiceStatusChanged" &&
            activityLogFormat(lastActivity.newValues)}
          <span>.</span>
          <span className="fw-bold"> {lastActivity.userName}</span>
          {` on ${dateFormatWithHours(lastActivity.createdDate)} (ET).  `}
          <NavHashLink
            smooth
            to={"#activity-section"}
            className="invoice-scroll-link"
          >
            View Activity
          </NavHashLink>
        </div>
      )}

      <label className="my-2 d-flex align-items-center">
        <span className="fw-bold me-2">Owner:</span>
        <div style={{ width: "150px" }}>
          <AccessControl
            allowedRoles={ROLE_BILLING_MODULE_PERFORM_ACTION}
            fallback={
              // Disabled select
              <Select
                options={users}
                isDisabled={true}
                value={
                  invoice.owner && {
                    value: invoice.owner.id,
                    label: invoice.owner.fullName,
                  }
                }
                isLoading={!users}
                onChange={undefined}
              />
            }
          >
            <Select
              placeholder="Select Owner"
              options={users}
              isDisabled={invoice.status.id !== PENDING || !invoice.isEditable}
              value={
                invoice.owner && {
                  value: invoice.owner.id,
                  label: invoice.owner.fullName,
                }
              }
              isLoading={!users}
              onChange={(owner: SelectItem) =>
                changeOwner({
                  id: invoice.id,
                  owner: { id: owner.value, fullName: owner.label },
                })
              }
            />
          </AccessControl>
        </div>
      </label>
      <div className="border rounded mt-3 pb-4">
        {invoice.isEditable && (
          <AccessControl allowedRoles={ROLE_BILLING_MODULE_PERFORM_ACTION}>
            <InvoiceActions
              invoice={invoice}
              updateInvoice={updateInvoice}
              sendReminders={sendReminders}
              loadInvoice={loadInvoice}
            />
          </AccessControl>
        )}
        <div className="p-4">
          <InvoiceHeader invoice={invoice} />
          <InvoiceBody invoice={invoice} />
          <InvoiceFooter invoice={invoice} />
        </div>
      </div>
    </div>
  );
};
